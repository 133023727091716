<template>
    <v-row>
        <v-col cols="12">
            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step :complete="step>1" :editable="step<3" step="1">
                        Project properties
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2">
                        Upload File
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3">
                        Complete!
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <h3 class="primary--text mb-3">1. Provide information about your project</h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select v-model="model.type" :error-messages="errors.type" :items="typeOptions" filled
                                          label="Choose project type *" @change="resetField('type')"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="model.name" :error-messages="errors.name" filled
                                              label="Enter project name *" placeholder="Project name..."
                                              @input="resetField('name')"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="model.description" filled hide-details
                                            label="Describe your project shortly"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="model.keywords" filled hide-details
                                              label="Add some keywords/tags to classify your project"></v-text-field>
                            </v-col>
                            <v-col cols="12"><small class="mb-4">"*" indicates required fields</small></v-col>
                            <v-col cols="12">
                                <v-btn color="primary" @click="onStep2">Continue
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <h3 class="primary--text mb-3">2. Upload some files to your project</h3>
                        <v-row>
                            <v-col cols="12">
                                <file-input v-model="model.uploadsImages"
                                            :accept="['image/png','image/jpg','image/gif','image/jpeg']"
                                            @input="fileError=false"></file-input>
                                <v-scroll-x-transition>
                                    <h5 v-if="fileError && !skipUploading" class="error--text">* Add at least one file
                                        for your project</h5>
                                </v-scroll-x-transition>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="model.processSamples" class="ml-2"
                                            hide-details label="I want to process images upon uploading"></v-checkbox>
                                <v-checkbox v-model="skipUploading" class="ml-2"
                                            hide-details
                                            label="I want to create an empty project so i could upload files later"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12">
                                <v-btn color="default">
                                    <v-icon class="mr-2">mdi-chevron-left</v-icon>
                                    Previous step
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" large @click="onSubmit">
                                    <v-icon class="mr-2" large>mdi-rocket-launch</v-icon>
                                    Launch project
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <div class="d-flex flex-column justify-center align-center">
                            <h1 class="warning--text mt-6">We are cooking your project</h1>
                            <h3 class="primary--text mb-16">Once the project is ready you will be redirected</h3>
                            <svg class="mb-16" height="200px" preserveAspectRatio="xMidYMid"
                                 style="margin:auto;background:transparent;display:block;" viewBox="0 0 100 100"
                                 width="200px"
                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g transform="translate(50 50)">
                                    <g transform="translate(-19 -19) scale(0.6)">
                                        <g>
                                            <animateTransform attributeName="transform" begin="0s"
                                                              dur="0.3333333333333333s"
                                                              keyTimes="0;1" repeatCount="indefinite" type="rotate"
                                                              values="0;45"></animateTransform>
                                            <path d="M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                                  fill="#febe1e"></path>
                                        </g>
                                    </g>
                                    <g transform="translate(19 19) scale(0.6)">
                                        <g>
                                            <animateTransform attributeName="transform" begin="-0.16666666666666666s"
                                                              dur="0.3333333333333333s"
                                                              keyTimes="0;1" repeatCount="indefinite"
                                                              type="rotate"
                                                              values="45;0"></animateTransform>
                                            <path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                                  fill="#22bfcd"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import formMixin from "@/mixins/form-mixin";
import FileInput from "@/components/Inputs/FileInput";

export default {
  mixins: [formMixin],
  components: {
    FileInput,
  },
  data: () => ({
    isBusy: false,
    skipUploading: false,
    fileError: false,
    model: {
      name: "",
      keywords: "",
      description: "",
      type: null,
      processSamples: true,
      uploadsImages: [],
      websiteUrls: [],
    },
    typeOptions: [
      { value: "PRINT_AD", text: "Print Ad" },
      { value: "INSTORE_SIGNAGE", text: "In-store signage" },
      { value: "OUTDOOR_BILLBOARD", text: "Outdoor billboard" },
      { value: "ONLINE_IMAGE", text: "Digital Ad Image" },
      { value: "PACKAGING", text: "Packaging" },
      { value: "OTHER", text: "Other" },
    ],
    step: 1,
    rules: {
      name: { isRequired: "Provide valid name for project" },
      type: { isRequired: "Choose appropriate project type from the list" },
    },
  }),
  methods: {
    onStep2() {
      if (this.validate()) {
        this.step = 2;
      }
    },
    onSubmit() {
      if (!this.skipUploading && !this.model.uploadsImages.length) {
        this.fileError = true;
        return;
      }
      this.step = 3;
      this.resetForm();
      this.isBusy = true;
      this.createProject({
        name: this.model.name,
        keywords: this.model.keywords,
        description: this.model.description,
        type: this.model.type,
        uploads: this.model.uploadsImages,
        websiteUrls: this.model.websiteUrls,
        processSamples: this.model.processSamples,
      })
          .then(({ name, uuid }) => {
            this.$success(`Project "${ name }" created successfully`);
            this.isBusy = false;
            this.$router.push({ name: "ProjectDetails", params: { uuid } });
          })
          .catch((error) => {
            this.handleServerErrors(error);
            this.isBusy = false;
          });
    },
    ...mapActions(["createProject"]),
    ...mapMutations(["invalidateProjectList"]),
  },
};
</script>
<style lang="scss">
//@import '../../../../../assets/sass/paper/variables';
//
//.project-create {
//
//  &__form-container {
//    display: flex;
//    flex-direction: column;
//  }
//
//  &__form-body {
//    flex: 1;
//  }
//
//  &__type-label {
//    margin: 0;
//    line-height: 40px;
//  }
//
//  &__form-submit {
//    flex: 0;
//  }
//}
</style>
