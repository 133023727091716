<template>
    <v-alert :color="color" :dismissible="dismissible" :type="type" :value="isVisible"
             border="left"
             elevation="2"
             prominent
             transition="scale-transition">
        {{ message }}
    </v-alert>
</template>
<script>
export default {
  name: "NotifyAlert",
  props: {
    message: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "success";
      },
    },
    dismissible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    timeout: {
      type: Number,
      default() {
        return 5000;
      },
    },
  },
  data: () => ({
    isVisible: false,
  }),
  computed: {
    color() {
      return `${ this.type } lighten-1`;
    },
  },
  mounted() {
    this.isVisible = true;
    if (this.timeout > 0) {
      setTimeout(() => {
        this.isVisible = false;
      }, this.timeout);
    }
  },
};
</script>

<style scoped>

</style>
