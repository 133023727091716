import {apiClient} from "@/api";
import {safeCopy} from "@/util";

export default {
    state: {
        media: {
            uuid: "",
            name: "",
            mime: "",
            url: "",
            thumb: "",
            projectUuid: "",
            ownerUuid: "",
            createdAt: "",
            updatedAt: "",
            project: {},
            owner: {},
            hasSample: false,
            hasAbc: false,
            hasAreas: false,
        },
        imageSample: {
            uuid: "",
            inputImage: "",
            inputImageThumb: "",
            outputImageRaw: "",
            outputImageHeatmap: "",
            outputImageGazeplot: "",
            outputImageOpacity: "",
            outputImageAoi: "",
            outputImageAes: "",
            outputImageRawThumb: "",
            outputImageHeatmapThumb: "",
            outputImageGazeplotThumb: "",
            outputImageOpacityThumb: "",
            outputImageAoiThumb: "",
            outputImageAesThumb: "",
            combinedImageRaw: "",
            combinedImageHeatmap: "",
            combinedImageGazeplot: "",
            combinedImageOpacity: "",
            analysisResult: 0,
            analysisOptions: 0,
            outputOptions: 0,
            viewDistanceOptions: 0,
            viewTypeOptions: 0,
            faceDetected: false,
            skinDetected: false,
            textDetected: false,
            complexity: 0,
            clear: 0,
            exciting: 0,
            balance: 0,
            isProcessing: false,
            ownerUuid: "",
            mediaUuid: "",
        },
        imageAbc: {
            uuid: "",
            name: "",
            inputImage: "",
            inputImageThumb: "",
            inputDesignType: "",
            inputAreasBrand: [],
            inputAreasComm: [],
            outputAreasBrand: [],
            outputAreasComm: [],
            brandScore: 0,
            commScore: 0,
            aethScore: 0,
            clarity: 0,
            balance: 0,
            exciting: 0,
            calm: 0,
            totalScore: 0,
            outputImageBrand: "",
            outputImageComm: "",
            outputImageHeatmap: "",
            outputImageBrandThumb: "",
            outputImageCommThumb: "",
            outputImageHeatmapThumb: "",
            combinedImageHeatmap: "",
            isProcessing: false,
            ownerUuid: "",
            mediaUuid: "",
        },
        imageAreas: [],
        mediaList: [],
        total: 0,
        skip: 0,
        limit: 12,
        refreshKey: 1,
    },
    getters: {
        getMediaList: ({mediaList}) => mediaList,
        getMediaTotal: ({total}) => total,
        getMedia: ({media}) => media,
        getMediaProject: ({media}) => media.project,
        getImageAreas: ({imageAreas}) => imageAreas,
        getImageAbc: ({imageAbc}) => imageAbc,
        getImageSample: ({imageSample}) => imageSample,
        getMediaRefreshKey: ({refreshKey}) => refreshKey
    },
    mutations: {
        setMedia({media}, newMediaInstance) {
            safeCopy(newMediaInstance, media);
            safeCopy(newMediaInstance.project, media.project);
            safeCopy(newMediaInstance.owner, media.owner);
        },
        setImageSample({imageSample, media}, newImageSampleInstance) {
            media.hasSample = false;
            imageSample.uuid = null;
            if (newImageSampleInstance !== null) {
                safeCopy(newImageSampleInstance, imageSample);
                media.hasSample = true
            }
        },
        resetImageSample({imageSample}) {
            safeCopy({
                uuid: "",
                inputImage: "",
                inputImageThumb: "",
                outputImageRaw: "",
                outputImageHeatmap: "",
                outputImageGazeplot: "",
                outputImageOpacity: "",
                outputImageAoi: "",
                outputImageAes: "",
                outputImageRawThumb: "",
                outputImageHeatmapThumb: "",
                outputImageGazeplotThumb: "",
                outputImageOpacityThumb: "",
                outputImageAoiThumb: "",
                outputImageAesThumb: "",
                combinedImageRaw: "",
                combinedImageHeatmap: "",
                combinedImageGazeplot: "",
                combinedImageOpacity: "",
                analysisResult: 0,
                analysisOptions: 0,
                outputOptions: 0,
                viewDistanceOptions: 0,
                viewTypeOptions: 0,
                faceDetected: false,
                skinDetected: false,
                textDetected: false,
                complexity: 0,
                clear: 0,
                exciting: 0,
                balance: 0,
                isProcessing: false,
                ownerUuid: "",
                mediaUuid: "",

            }, imageSample);
        },
        setImageAreas({media, imageAreas}, newAreas) {
            media.hasAreas = (newAreas.length > 0);
            imageAreas.splice(0, imageAreas.length);
            for (const area of newAreas) {
                imageAreas.push(area);
            }
        },
        setImageAbc({media, imageAbc}, newImageAbc) {
            media.hasAbc = (newImageAbc !== null);
            imageAbc.uuid = null;
            if (media.hasAbc) {
                safeCopy(newImageAbc, imageAbc);
            }
        },
        updateMediaRefreshKey(state) {
            state.refreshKey++;
        },
        maybeUpdateSample({media, imageSample, mediaList}, {mediaUuid, imageAttention}) {
            if (mediaUuid === media.uuid) {
                this.commit("resetImageSample")
                if (media.uuid === mediaUuid) {
                    safeCopy(imageAttention, imageSample);
                    media.hasSample = true;
                }
            }
            for (const m of mediaList) {
                if (m.sample && m.uuid === mediaUuid) {
                    m.sample.uuid = imageAttention.uuid;
                    m.sample.isProcessing = imageAttention.isProcessing;
                    return;
                }
            }
        },
        maybeDeleteSample({imageSample, media, mediaList}, mediaUuid) {
            if (media.uuid === mediaUuid) {
                imageSample.uuid = null;
                imageSample.isProcessing = false;
                media.hasSample = false;
            }
            for (const m of mediaList) {
                if (m.uuid === mediaUuid) {
                    m.hasSample = false;
                    m.sample = null;
                }
            }
        },
        maybeUpdateAoi({imageAreas, media}, {mediaUuid, imageAoi}) {
            if (mediaUuid === media.uuid) {
                for (const element of imageAreas) {
                    if (element.uuid === imageAoi.uuid) {
                        safeCopy(imageAoi, element);
                        return;
                    }
                }
            }
        },
        maybeDeleteAoi({imageAreas, media}, {imageAoi, mediaUuid}) {
            if (mediaUuid === media.uuid) {
                const index = imageAreas.findIndex((element) => element.uuid === imageAoi.uuid);
                if (index >= 0) {
                    imageAreas.splice(index, 1);
                }
                media.hasAreas = (imageAreas > 0);
            }
        },
        maybeUpdateAbc(state, {mediaUuid, imageAbc}) {
            if (mediaUuid === state.media.uuid) {
                if (state.media.uuid === mediaUuid) {
                    state.media.hasAbc = true;
                    safeCopy(imageAbc, state.imageAbc);
                }
            }
            for (const m of state.mediaList) {
                if (m.abc && m.uuid === mediaUuid) {
                    m.hasSample = true;
                    m.abc.uuid = imageAbc.uuid;
                    m.abc.isProcessing = imageAbc.isProcessing;
                }
            }
        },
        maybeDeleteAbc({imageAbc, media, mediaList}, mediaUuid) {
            if (media.uuid === mediaUuid) {
                imageAbc.uuid = null;
                imageAbc.isProcessing = false;
                media.hasAbc = false;
            }
            for (const m of mediaList) {
                if (m.uuid === mediaUuid) {
                    m.hasAbc = false;
                    m.abc = null;
                }
            }
        },
        updateMediaName({media, mediaList}, {mediaUuid, name}) {
            if (media.uuid === mediaUuid) {
                media.name = name;
            }
            for (const element of mediaList) {
                if (element.uuid === mediaUuid) {
                    element.name = name;
                    return;
                }
            }
        },
        appendMedia(state, appendMediaList) {
            state.mediaList = [
                ...state.mediaList,
                ...appendMediaList,
            ];
        },
        invalidateMediaList(state) {
            state.mediaList.splice(0, state.mediaList.length);
            state.skip = 0;
            state.total = 0;
        },
    },
    actions: {
        fetchMediaList({state, commit}, {loadMore, projectUuid}) {
            return new Promise((resolve, reject) => {
                apiClient
                    .service("media")
                    .find({
                        query: {
                            projectUuid,
                            $limit: state.limit,
                            $skip: loadMore ? state.skip : 0,
                        },
                    })
                    .then(({total, data}) => {
                        if (!loadMore) {
                            state.mediaList.splice(0, state.mediaList.length);
                            state.skip = 0;
                        }
                        state.total = total;
                        state.skip += data.length;
                        commit("appendMedia", data);
                        return resolve(state.mediaList);
                    })
                    .catch(reject);
            });
        },
        fetchMedia({state, commit}, uuid) {
            state.media.hasSample = false;
            this.commit('resetImageSample')
            return new Promise((resolve, reject) => {
                apiClient
                    .service("media")
                    .get(uuid)
                    .then((mediaInstance) => {
                        state.media.hasSample = false;
                        state.media.hasAbc = false;
                        state.media.hasAreas = false;
                        state.imageAreas.splice(0, state.imageAreas.length);
                        commit("setMedia", mediaInstance);
                        commit("setImageAbc", mediaInstance.abc);
                        commit("setImageSample", mediaInstance.sample);
                        commit("setImageAreas", mediaInstance.areas);
                        state.refreshKey++;
                        resolve(state.media);
                    })
                    .catch(reject);
            });
        },
        fetchMediaAreas({state, commit}, mediaUuid) {
            return new Promise((resolve, reject) => {
                apiClient
                    .service("image-aoi")
                    .find({query: {mediaUuid}})
                    .then((imageAreas) => {
                        commit("setImageAreas", imageAreas);
                        resolve(state.imageAreas);
                    })
                    .catch(reject);
            });
        },
        createImageSample({state, commit}, mediaUuid) {
            return new Promise((resolve, reject) => {
                return apiClient
                    .service("image-attention")
                    .create({mediaUuid})
                    .then((imageAttention) => {
                        commit("setImageSample", imageAttention);
                        resolve(state.imageSample);
                    })
                    .catch(reject);
            });
        },
        createImageArea(context, {mediaUuid, inputAreas, name}) {
            return new Promise((resolve, reject) => {
                apiClient
                    .service("image-aoi")
                    .create({
                        mediaUuid,
                        inputAreas,
                        name,
                    }).then(resolve)
                    .catch(reject);
            });
        },
        createImageAbc({state, commit}, {mediaUuid, inputAreasBrand, inputAreasComm, inputDesignType}) {
            return new Promise((resolve, reject) => {
                apiClient.service("image-abc")
                    .create({mediaUuid, inputAreasBrand, inputAreasComm, inputDesignType})
                    .then((imageAbc) => {
                        commit("setImageAbc", imageAbc);
                        resolve(state.imageAbc);
                    })
                    .catch(reject);
            });
        },
        deleteMedia(context, uuid) {
            return apiClient.service("media").remove(uuid);
        },
        updateMedia(context, {uuid, name}) {
            return apiClient.service("media").patch(uuid, {name});
        },
    },
};
