<template>
    <div ref="drag-container" :class="{'is-dragging':isDragging}" class="file-input"
         @dragover.prevent.stop="onFileDragover" @drop.prevent.stop="onFileDrop" @dragend.prevent.stop="onFileDrop"
         @dragenter.prevent.stop="onFileDragEnter" @dragleave.prevent.stop.exact="onFileDragLeave">
        <div class="file-input__container">
            <h3 class="file-input__drag-title">Drag files here</h3>
            <div class="file-input__or-title">-or-</div>
            <div class="file-input__button-container">
                <v-btn
                        :loading="isSelecting"
                        class="my-3"
                        color="warning"
                        text
                        x-large
                        @click="handleFileImport"
                >
                    <v-icon class="mr-2">mdi-folder</v-icon>
                    Browse Files
                </v-btn>
                <input ref="file" :accept="this.accept" clas="d-none" multiple type="file"
                       @change="onFileInputChange">
            </div>
        </div>
        <v-row class="mt-3 px-2 py-2" dense no-gutters>
            <div v-for="(file, index) in fileList" :key="index" class="file-input__file-preview">
                <v-hover>
                    <template v-slot:default="{ hover }">
                        <v-card class="file-input__file-preview-image" elevation="2" width="200">
                            <v-img :src="file.previewUrl" contain height="200" width="200" @load="onImageLoad"></v-img>
                            <span class="file-input__file-preview-image-title warning--text">{{ file.name }}</span>
                            <v-fade-transition>
                                <v-overlay
                                        v-if="hover"
                                        absolute
                                        class="text-center"
                                        color="primary"
                                >
                                    <v-btn class="file-input__file-preview-image-remove text-uppercase"
                                           color="error" small @click="onDeleteFileItem(index)">
                                        <v-icon class="mr-1" small>mdi-delete</v-icon>
                                        Remove
                                    </v-btn>
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </template>
                </v-hover>
            </div>
        </v-row>
    </div>
</template>
<script>

export default {
  props: {
    maxFiles: { type: Number, default: () => 10 },
    accept: { type: Array, default: () => ([]) },
    value: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      isDragging: false,
      isSelecting: false,
      fileList: this.value,
      //previewUrls: [],
      dragTarget: null,
    };
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener("focus", () => {
        this.isSelecting = false;
      }, { once: true });
      // Trigger click on the FileInput
      this.$refs.file.click();
    },
    onImageLoad(event) {
      URL.revokeObjectURL(event);
    },
    onDeleteFileItem(index) {
      this.fileList.splice(index, 1);
//            this.previewUrls.splice(index,1);
      this.$emit("input", this.fileList);
    },
    onFileInputChange() {
      for (const file of this.$refs.file.files) {
        if ((this.fileList.length < this.maxFiles) && (!this.accept.length || (this.accept.length && this.accept.includes(file.type)))) {
          //const url = URL.createObjectURL(file);
          file.previewUrl = URL.createObjectURL(file);
          this.fileList.push(file);
        }
      }
      this.$emit("input", this.fileList);
    },
    onFileDragover() {
    },
    onFileDrop(event) {
      this.$refs.file.files = event.dataTransfer.files || event.target.files;
      this.onFileInputChange();
      this.isDragging = false;
    },
    onFileDragEnter(event) {
      this.dragTarget = event.target;
      this.isDragging = true;
    },
    onFileDragLeave(event) {
      if (this.dragTarget == event.target) {
        this.isDragging = false;
      }
    },
  },
};
</script>
<style lang="scss">


.file-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  min-height: 400px;
  border: 2px dashed rgba(54, 54, 54, 0.2);

  &.is-dragging {
    border: 2px dashed #febe1e;
  }

  &__drag-title {
    padding: 0;
    color: #dddddd;
    font-size: 4em;
    margin: 1.0em 0 0em 0em;
    text-align: center;
  }

  &__or-title {
    margin: 0 0 0.3em;
    text-align: center;
    color: #51bcda;
    font-style: italic;
    font-weight: 400;
    font-size: 1.5em;
  }

  &__button-container {
    text-align: center;

    label {
      display: inline-block;
    }

    input[type="file"] {
      display: none;
    }
  }

  &__container {
    width: 100%;
  }

  &__file-preview {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    padding: 15px;

    &-image {
      &-remove {

      }

      &-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: white;
        background: rgba(54, 54, 54, 0.8);
        border-radius: 0 0 0 0 !important;
        box-sizing: border-box;
        padding: 5px 15px;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
  }

  &__file-list {
    width: 80%;
    list-style: none;
    margin-top: 1em;

    &__item {
      padding: 3px;
      display: flex;
      flex-direction: row;

      &__dots {
        flex: 1;
        border-bottom: 1px dashed #22bfcd;
      }

      &__remove {
        font-size: 16px;
        line-height: 20px;
        color: #EF8157;
        cursor: pointer;

        &:hover {
          color: #EF8157;
        }
      }
    }
  }
}
</style>
