import { apiClient } from "@/api";
import store from "../store";

export default function auth({ next, from }) {
  console.log("Auth check");
  //reAuthenticate()
  apiClient.authenticate()
           .then(({ user }) => {
             store.commit("setUserAuthInfo", user);
             return next();
           })
           .catch((error) => {
             console.error(error);
             if (from && from.name === "Login") {
               return next(false);
             }
             return next({ name: "Login" });
           });
}
