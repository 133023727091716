<template>
    <v-row class="abc-tab">
        <v-col v-if="!media.hasAbc || isRepeating" cols="12">
            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">
                        Aesthetics
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 2" step="2">
                        Branding
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 3" step="3">
                        Communications
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step>3" step="4">
                        Launch!
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card flat>
                            <v-card-title>Do you want the design to be <span class="warning--text px-1">exciting</span>
                                or <span class="primary--text px-1">calm</span>?
                            </v-card-title>
                            <v-card-text>
                                <v-radio-group v-model="model.inputDesignType" :error-messages="errors.inputDesignType"
                                               @change="resetField('inputDesignType')">
                                    <template v-slot:label>
                                        So it's gonna be:
                                    </template>
                                    <v-radio value="exciting">
                                        <template v-slot:label>
                                            <div>Of course it will be <strong class="warning--text">exciting</strong>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="calm">
                                        <template v-slot:label>
                                            <div>Definitely <strong class="primary--text">calm</strong></div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-card-text>
                        </v-card>
                        <v-btn color="primary" @click="onStep2">
                            Continue
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card flat>
                            <v-card-title>Using the
                                <v-icon class="mx-2">mdi-pencil-box-multiple-outline</v-icon>
                                pencil tool, select branding areas of interest.
                            </v-card-title>
                            <area-editor v-model="model.inputAreasBrand" :image="media.url" class="mt-6">
                                <template v-slot:append>
                                    <v-btn :disabled="!model.inputAreasBrand.length" class="ml-2" color="primary"
                                           @click="step=3">Next step
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                            </area-editor>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card flat>
                            <v-card-title>Using the
                                <v-icon class="mx-2">mdi-pencil-box-multiple-outline</v-icon>
                                pencil tool, select communication areas of interest.
                            </v-card-title>
                            <area-editor v-model="model.inputAreasComm" :image="media.url">
                                <template v-slot:append>
                                    <v-btn class="mx-2" color="default" @click="step=2">
                                        <v-icon class="mr-2">mdi-chevron-left</v-icon>
                                        Go Back
                                    </v-btn>
                                    <v-btn :disabled="!model.inputAreasComm.length" class="ml-2" color="primary"
                                           @click="onSubmit">Finish
                                    </v-btn>
                                </template>
                            </area-editor>
                        </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="4">
                        <v-card flat>
                            <v-card-title class="primary--text text-h1 text-center justify-center">Processing...
                            </v-card-title>
                            <svg height="300"
                                 preserveAspectRatio="xMidYMid"
                                 style="margin:auto;background:transparent;display:block;" viewBox="0 0 100 100"
                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g transform="translate(50 50)">
                                    <g transform="translate(-19 -19) scale(0.6)">
                                        <g>
                                            <animateTransform attributeName="transform" begin="0s"
                                                              dur="0.3333333333333333s" keyTimes="0;1"
                                                              repeatCount="indefinite" type="rotate"
                                                              values="0;45"></animateTransform>
                                            <path d="M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                                  fill="#febe1e"></path>
                                        </g>
                                    </g>
                                    <g transform="translate(19 19) scale(0.6)">
                                        <g>
                                            <animateTransform attributeName="transform" begin="-0.16666666666666666s"
                                                              dur="0.3333333333333333s" keyTimes="0;1"
                                                              repeatCount="indefinite"
                                                              type="rotate"
                                                              values="45;0"></animateTransform>
                                            <path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                                  fill="#22bfcd"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </v-card>
                        <v-card-subtitle class="text-h2 text-uppercase grey--text text-center">stand by
                        </v-card-subtitle>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>

        <v-col v-if="media.hasAbc && abc.isProcessing" cols="12">
            <v-card flat>
                <v-card-title class="primary--text text-h1 text-center justify-center">Almost there...!</v-card-title>
                <svg height="300"
                     preserveAspectRatio="xMidYMid"
                     style="margin:auto;background:transparent;display:block;" viewBox="0 0 100 100"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g transform="translate(50 50)">
                        <g transform="translate(-19 -19) scale(0.6)">
                            <g>
                                <animateTransform attributeName="transform" begin="0s"
                                                  dur="0.3333333333333333s" keyTimes="0;1"
                                                  repeatCount="indefinite" type="rotate"
                                                  values="0;45"></animateTransform>
                                <path d="M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                      fill="#febe1e"></path>
                            </g>
                        </g>
                        <g transform="translate(19 19) scale(0.6)">
                            <g>
                                <animateTransform attributeName="transform" begin="-0.16666666666666666s"
                                                  dur="0.3333333333333333s" keyTimes="0;1"
                                                  repeatCount="indefinite"
                                                  type="rotate"
                                                  values="45;0"></animateTransform>
                                <path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                      fill="#22bfcd"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </v-card>
        </v-col>

        <v-col v-if="media.hasAbc && !abc.isProcessing && !isRepeating"
               class="px-xl-16 py-xl-16 px-lg-10 py-lg-10 px-md-8 py-md-8 px-sm-4 py-sm-4 px-xs-2 py-xs-2"
               cols="12">
            <v-row no-gutters>
                <v-col class="justify-center align-center" cols="12" md="6" style="transform: rotate(2deg)">
                    <div class="image-frame" @click="onGalleryShow(0)">
                        <v-img
                                :src="abc.outputImageHeatmap"
                                :style="{ backgroundImage: `url(${abc.inputImage})`, backgroundSize:'contain', backgroundPosition:'center center' }"
                                class="image-heat"
                                contain
                                height="auto" max-height="100%" width="100%"></v-img>
                        <span class="image-frame-caption">Heatmap</span>
                        <span class="paper-clip"></span>
                    </div>
                </v-col>
                <v-col class="px-6 py-6 d-flex justify-center align-center" cols="12" md="6">
                    <v-progress-circular :size="totalScoreProgressSize" :value="abc.totalScore"
                                         :width="totalScoreProgressWidth"
                                         color="primary">
                        <div class="total-score-block d-flex flex-column align-center justify-center">
                            <div :class="`total-score-block-label-${$vuetify.breakpoint.name}`">SCORE</div>
                            <div :class="`total-score-block-value-${$vuetify.breakpoint.name}`">
                                {{ Math.round(abc.totalScore) }}
                            </div>
                        </div>
                    </v-progress-circular>
                </v-col>
                <v-col class="justify-center d-flex align-center" cols="4">
                    <div class="score-block primary px-6 py-6 my-8">
                        <div class="score-block-label">Aesthetics</div>
                        <div class="score-block-value">{{ Math.round(abc.aethScore * 10) }}</div>
                    </div>
                </v-col>
                <v-col class="justify-center d-flex align-center" cols="4">
                    <div class="score-block primary px-6 py-6 my-8">
                        <div class="score-block-label">Branding</div>
                        <div class="score-block-value">{{ Math.round(abc.brandScore * 10) }}</div>
                    </div>
                </v-col>
                <v-col class="justify-center d-flex align-center" cols="4">
                    <div class="score-block primary px-6 py-6 my-8">
                        <div class="score-block-label">Communication</div>
                        <div class="score-block-value">{{ Math.round(abc.commScore * 10) }}</div>
                    </div>
                </v-col>
                <v-col class="d-flex flex-column" cols="12" lg="4" md="12">
                    <v-row align="center" class="mb-sm-4 mb-xs-4 mb-md-0 mb-lg-0 mb-xl-0" justify="center" no-gutters>
                        <v-col class="text-center" cols="12" lg="12" md="4" sm="4" xl="12" xs="4">
                            <v-progress-circular v-if="abc.inputDesignType==='exciting'" :value="abc.exciting * 10"
                                                 color="pink darken-4" size="140" width="20">
                                <div class="secondary-score-block d-flex flex-column align-center justify-center">
                                    <div class="secondary-score-block-label">Excitement</div>
                                    <div class="secondary-score-block-value">{{ abc.exciting * 10 }}</div>
                                </div>
                            </v-progress-circular>
                            <v-progress-circular v-if="abc.inputDesignType==='calm'" :value="abc.calm * 10"
                                                 color="blue lighten-2" size="140" width="20">
                                <div class="secondary-score-block d-flex flex-column align-center justify-center">
                                    <div class="secondary-score-block-label">Calm</div>
                                    <div class="secondary-score-block-value">{{ abc.calm * 10 }}</div>
                                </div>
                            </v-progress-circular>
                        </v-col>
                        <v-col class="text-center" cols="12" lg="6" md="4" sm="4" xs="4">
                            <v-progress-circular :value="abc.clarity * 10" color="orange" size="140" width="20">
                                <div class="secondary-score-block d-flex flex-column align-center justify-center">
                                    <div class="secondary-score-block-label">Clarity</div>
                                    <div class="secondary-score-block-value">{{ abc.clarity * 10 }}</div>
                                </div>
                            </v-progress-circular>
                        </v-col>
                        <v-col class="text-center" cols="12" lg="6" md="4" sm="4" xs="4">
                            <v-progress-circular :value="abc.balance * 10" color="green darken-3" size="140" width="20">
                                <div class="secondary-score-block d-flex flex-column align-center justify-center">
                                    <div class="secondary-score-block-label">Balance</div>
                                    <div class="secondary-score-block-value">{{ abc.balance * 10 }}</div>
                                </div>
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col class="justify-center align-center px-4 py-4" cols="12" lg="4" md="6">
                    <div class="image-frame" style="transform: rotate(3deg)" @click="onGalleryShow(1)">
                        <v-img :src="abc.outputImageBrandThumb" contain height="100%" width="100%"></v-img>
                        <span class="image-frame-caption">Branding</span>
                        <span class="paper-clip"></span>
                    </div>
                </v-col>
                <v-col class="justify-center align-center px-4 py-4" cols="12" lg="4" md="6">
                    <div class="image-frame" style="transform: rotate(-2deg)" @click="onGalleryShow(2)">
                        <v-img :src="abc.outputImageCommThumb" contain height="100%" width="100%"></v-img>
                        <span class="image-frame-caption">Communication</span>
                        <span class="paper-clip"></span>
                    </div>
                </v-col>
                <v-col class="text-md-right text-sm-center text-xs-center" cols="12">
                    <v-btn class="mt-6" color="primary" dark x-large @click="onRepeat">
                        <v-icon>mdi-sync</v-icon>
                        Repeat the analysis
                    </v-btn>
                </v-col>
            </v-row>
            <light-box v-if="gallery.length" ref="lightbox" :disable-zoom="false" :media="gallery" :show-caption="true"
                       :show-footer-count="false" :show-light-box="false" :show-thumbs="true">
                <template slot="close" slot-scope="{ closeFn,mediaItem }">
                    <a :href="gallery[mediaItem].download">
                        <v-icon
                                class="white--text vue-lb-header-button mr-4"
                                title="Download"
                                white
                        >mdi-download
                        </v-icon>
                    </a>
                    <v-icon
                            class="white--text vue-lb-header-button mr-2"
                            large
                            title="Close"
                            type="button"
                            @click.stop="closeFn"
                    >mdi-close
                    </v-icon>
                </template>
            </light-box>
        </v-col>
    </v-row>
</template>

<script>
import AreaEditor from "@/components/Inputs/AreaEditor";
import { mapActions, mapGetters } from "vuex";
import formMixin from "@/mixins/form-mixin";
import LightBox from "@/components/LightBox/LightBox";
import { apiClient } from "@/api";
import { getUrlPath } from "@/util";


export default {
  name: "AbcScoreTab",
  mixins: [formMixin],
  components: {
    AreaEditor,
    LightBox,
  },
  data: () => ({
    step: 1,
    isRepeating: false,
    model: {
      inputDesignType: null,
      inputAreasBrand: [],
      inputAreasComm: [],
    },
    rules: {
      inputDesignType: { isRequired: "You must choose one of the options" },
    },
    size: {
      xl: 500,
      lg: 400,
      md: 300,
      sm: 400,
      xs: 400,
    },
    width: {
      xl: 50,
      lg: 40,
      md: 40,
      sm: 40,
      xs: 40,
    },
  }),
  computed: {
    ...mapGetters({ media: "getMedia", abc: "getImageAbc" }),
    totalScoreProgressSize() {
      return this.size[this.$vuetify.breakpoint.name];
    },
    totalScoreProgressWidth() {
      return this.width[this.$vuetify.breakpoint.name];
    },
    gallery() {
      return this.media.hasAbc ? [
        {
          src: this.abc.combinedImageHeatmap,
          download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.abc.combinedImageHeatmap))}`,
          thumb: this.abc.outputImageHeatmapThumb,
          caption: "Heat Map",
          backgroundImageThumb: this.abc.inputImageThumb,
        },
        {
          src: this.abc.outputImageBrand,
          download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.abc.outputImageBrand))}`,
          thumb: this.abc.outputImageBrandThumb,
          caption: "Brand Areas",
        },
        {
          src: this.abc.outputImageComm,
          download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.abc.outputImageComm))}`,
          thumb: this.abc.outputImageCommThumb,
          caption: "Communication Areas",
        },
      ] : [];
    },
  },
  methods: {
    onStep2() {
      if (this.validate()) {
        this.step = 2;
      }
    },
    onSubmit() {
      this.step = 4;
      this.createImageAbc({
        mediaUuid: this.media.uuid,
        inputAreasBrand: this.model.inputAreasBrand.map(({ x, y, width, height }, index) => ({
          id: `uuid${ index }`,
          units: "pixels",
          left: Math.round(x),
          top: Math.round(y),
          width: Math.round(width),
          height: Math.round(height),
          text: `Area ${ index }`,
        })),
        inputAreasComm: this.model.inputAreasComm.map(({ x, y, width, height }, index) => ({
          id: `uuid${ index }`,
          units: "pixels",
          left: Math.round(x),
          top: Math.round(y),
          width: Math.round(width),
          height: Math.round(height),
          text: `Area ${ index }`,
        })),
        inputDesignType: this.model.inputDesignType,
      }).finally(() => {
        this.isRepeating = false;
      });
    },
    onRepeat() {
      this.isRepeating = true;
      this.step = 1;
    },
    onGalleryShow(index) {
      this.$refs.lightbox.showImage(index);
    },
    onDownload(index) {
      this.downloadPending = true;
      this.downloadModal = true;
      apiClient
        .service("image-abc")
        .get(this.abc.uuid, { query: { download: this.downloadItems[index] } })
        .then((url) => {
          this.downloadUrl = url;
        }).catch(() => {
        this.downloadModal = false;
      }).finally(() => {
        this.downloadPending = false;
      });
    },
    ...mapActions(["createImageAbc"]),
  },
};
</script>

<style lang="scss">
.abc-tab {

  .image-frame {
    padding: 16px 16px 50px 16px;
    background-color: #eee;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background-image: url('~@/assets/images/pin.png');
      background-position: center center;
      background-size: contain;
      position: absolute;
      top: -20px;
      left: 50%
    }

    &-caption {
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 15px;
      text-align: center;
      background: transparent;
      font-size: 1.5em;
      font-style: italic;
      color: #22bfcd;
    }

    //span.paper-clip:after {
    //  width: 11px;
    //  height: 20px;
    //  content: " ";
    //  background: transparent;
    //  display: block;
    //  position: absolute;
    //  right: 2px;
    //  top: 5px;
    //  border-radius: 10px;
    //  border: 2px solid #22bfcd;
    //  border-bottom: 0;
    //  border-bottom-right-radius: 0;
    //  border-bottom-left-radius: 0;
    //}
    //span.paper-clip {
    //  background: transparent;
    //  height: 40px;
    //  width: 15px;
    //  border-radius: 10px;
    //  border: 2px solid #22bfcd;
    //  display: inline-block;
    //  position: absolute;
    //  left: -4px;
    //  bottom: 15px;
    //  transform: rotate(-30deg);
    //}

  }

  .total-score-block {
    &-label-xl {
      color: #febe1e;
      font-size: 4em;
    }

    &-value-xl {
      font-size: 8em;
      line-height: 60px;
    }

    &-label-lg {
      color: #febe1e;
      font-size: 3em;
    }

    &-value-lg {
      font-size: 6em;
      line-height: 60px;
    }

    &-label-md {
      color: #febe1e;
      font-size: 2em;
    }

    &-value-md {
      font-size: 4em;
      line-height: 60px;
    }

    &-label-sm {
      color: #febe1e;
      font-size: 4em;
    }

    &-value-sm {
      font-size: 8em;
      line-height: 60px;
    }

    &-label-xs {
      color: #febe1e;
      font-size: 4em;
    }

    &-value-xs {
      font-size: 8em;
      line-height: 60px;
    }
  }


  .score-block {
    color: white;
    font-weight: 800;
    width: 300px;
    text-align: center;

    &-label {
      font-size: 2em;
    }

    &-value {
      font-size: 8em;
      color: #febe1e;
    }
  }


}
</style>
