<template>
    <div>
        <v-row v-if="media.hasSample && !imageSample.isProcessing" class="mt-4">
            <v-col class="d-flex justify-start align-start mb-4" cols="12" lg="3" md="3" order="1" order-lg="1"
                   order-md="1" order-sm="1"
                   order-xl="0" order-xs="1" xl="3">
                <v-img :src="imageSample.outputImageHeatmapThumb"
                       :style="{ backgroundImage: `url(${imageSample.inputImageThumb})`, backgroundSize:'contain', backgroundPosition:'center center' }"
                       class="hidden-sm-and-down"
                       contain
                       style="cursor: pointer"
                       width="300"
                       @click="onGalleryShow(1)"></v-img>
            </v-col>
            <v-col class="border-left mb-4" cols="12" lg="9" md="9" order="2" order-lg="2" order-md="2" order-sm="2"
                   order-xl="1"
                   order-xs="2" xl="3">
                <v-card-title class="grey--text text-h4">Heat map.</v-card-title>
                <v-card-subtitle class="primary--text">Which aspects of your design will get the most attention?
                </v-card-subtitle>
                <v-img :src="imageSample.outputImageHeatmapThumb"
                       :style="{ backgroundImage: `url(${imageSample.inputImageThumb})`, backgroundSize:'contain', backgroundPosition:'center center' }"
                       class="hidden-md-and-up float-right ml-3"
                       contain
                       style="cursor: pointer"
                       width="200"
                       @click="onGalleryShow(1)"></v-img>
                <v-card-text class="text-justify">The Heat Map shows which areas of the image are most attractive to the
                    eye. “Hot”
                    areas are those that are best at attracting attention in the first few seconds, and “cold”
                    areas are weaker.
                </v-card-text>
                <v-card-text class="text-justify">
                    Green, yellow, and red represent low, medium, and high levels of attention. Areas with
                    no color are likely to be overlooked.
                </v-card-text>
            </v-col>
            <v-col class="mb-4 text-center" cols="12" order="0" order-lg="0" order-md="0" order-sm="0" order-xl="2"
                   order-xs="0"
                   xl="6">
                <h4 class="grey--text text-center text-h4 mb-2">Original image</h4>
                <img :src="imageSample.inputImage" style="max-width: 100%;height:auto; cursor: pointer"
                     @click="onGalleryShow(0)">
            </v-col>
            <v-col class="border-right mb-4" cols="12" lg="9" md="9" order="3" order-lg="3" order-md="3" order-sm="3"
                   order-xl="3" order-xs="3">
                <v-card-title class="grey--text text-h4">Gaze plot.</v-card-title>
                <v-card-subtitle class="primary--text">Where do viewers look, and in what order?
                </v-card-subtitle>
                <v-img :src="imageSample.outputImageGazeplotThumb"
                       :style="{ backgroundImage: `url(${imageSample.inputImageThumb})`, backgroundSize:'contain', backgroundPosition:'center center' }"
                       class="hidden-md-and-up float-left mr-3"
                       contain
                       style="cursor: pointer"
                       width="200"
                       @click="onGalleryShow(2)"></v-img>
                <v-card-text class="text-justify">The Gaze Plot illustrates the most likely viewing sequence for your
                    design, with visual
                    fixation areas presented in order.
                </v-card-text>
                <v-card-text class="text-justify">The numbers in each circle represent the sequence, which is based on
                    how likely each
                    area is to be viewed. The size of the circles represents how much attention this fixation
                    point would get. The circles move from red to green to indicate how quickly they are
                    noticed. Fixation points represented by red circles are expected to be viewed within the
                    first 2.5 seconds.
                </v-card-text>
            </v-col>
            <v-col class="d-flex justify-start mb-4" cols="12" lg="3" md="3" order="4" order-lg="4" order-md="4"
                   order-sm="4" order-xl="4" order-xs="4">
                <v-img :src="imageSample.outputImageGazeplotThumb"
                       :style="{ backgroundImage: `url(${imageSample.inputImageThumb})`, backgroundSize:'contain', backgroundPosition:'center center' }"
                       class="hidden-sm-and-down flex-grow-0"
                       contain
                       style="cursor: pointer"
                       width="300"
                       @click="onGalleryShow(2)"></v-img>
            </v-col>
            <v-col class="d-flex justify-end mb-4" cols="12" lg="3" md="3" order="5" order-lg="5" order-md="5"
                   order-sm="5"
                   order-xl="5" order-xs="5">
                <v-img :src="imageSample.outputImageOpacityThumb" class="flex-grow-0 hidden-sm-and-down"
                       contain style="cursor: pointer"
                       width="300"
                       @click="onGalleryShow(3)"></v-img>
            </v-col>
            <v-col class="border-left mb-4" cols="12" lg="9" md="9" order="6" order-lg="6" order-md="6" order-sm="6"
                   order-xl="6" order-xs="6">
                <v-card-title class="grey--text text-h4">Opacity map.</v-card-title>
                <v-card-subtitle class="primary--text">Which parts of your design are noticed versus being ignored?
                </v-card-subtitle>
                <v-img :src="imageSample.outputImageOpacityThumb" class="float-right hidden-md-and-up ml-3"
                       contain style="cursor: pointer"
                       width="200"
                       @click="onGalleryShow(3)"></v-img>
                <v-card-text class="text-justify">The Opacity Map helps you see what your viewers will see. The parts of
                    your design
                    that will be noticed within the first few seconds will be clearly visible in this map. The
                    elements that are noticed later will be semi-visible, and those that aren’t noticed will be
                    black.
                </v-card-text>
            </v-col>
            <v-col class="mb-4 text-center" cols="12" order="7" order-lg="7" order-md="7" order-sm="7" order-xl="7"
                   order-xs="7"
                   xl="6">
                <h4 class="text-h4 grey--text text-center hidden-xl-only">Aesthetics report.</h4>
                <v-card-subtitle class="primary--text text-center hidden-xl-only">Enables you to examine the emotional
                    aspects of aesthetics.
                </v-card-subtitle>
                <img :src="imageSample.outputImageAes" style="max-width: 100%;height:auto;cursor: pointer"
                     @click="onGalleryShow(4)">
            </v-col>
            <v-col class="border-left mb-4" cols="12" order="8" order-lg="8" order-md="8" order-sm="8" order-xl="8"
                   order-xs="8"
                   xl="6">
                <v-card-title class="grey--text text-h4 hidden-lg-and-down">Aesthetics report.</v-card-title>
                <v-card-subtitle class="primary--text hidden-lg-and-down">Does your design have the elements to make in
                    visually appealing?
                </v-card-subtitle>
                <v-card-title>Excitement</v-card-title>
                <v-card-text class="text-justify">
                    This score indicates how exciting and colorful the design is. The value ranges from 0
                    to 100, with a high score meaning it is more exciting. A low score means the design is
                    calm and sedated. Depending on your strategy, you may prefer excitement or calm.
                </v-card-text>
                <v-card-title>Clarity</v-card-title>
                <v-card-text class="text-justify">
                    The value for the clarity score ranges from 0 (which means a very complicated and
                    complex design) to 100 (which represents a simple and uncluttered design). Less
                    complicated and cleaner designs tend to be perceived as attractive.
                </v-card-text>
                <v-card-title>Balance</v-card-title>
                <v-card-text class="text-justify">
                    This score, which ranges from 0 to 100, indicates how symmetrical and harmonic the
                    design is. Besides the score, the output outlines the results with the color grids. The
                    cyan grid indicates color symmetry while the magenta grid outlines intensity symmetry.
                </v-card-text>
            </v-col>

            <light-box v-if="gallery.length" ref="lightbox" :disable-zoom="false" :media="gallery" :show-caption="true"
                       :show-footer-count="false" :show-light-box="false" :show-thumbs="true">
                <template slot="close" slot-scope="{ closeFn,mediaItem }">
                    <a :href="gallery[mediaItem].download" title="Download">
                        <v-icon
                                class="white--text vue-lb-header-button mr-4"
                                white
                        >mdi-download
                        </v-icon>
                    </a>
                    <v-icon
                            class="white--text vue-lb-header-button mr-2"
                            large
                            title="Close"
                            type="button"
                            @click.stop="closeFn"
                    >mdi-close
                    </v-icon>
                </template>
            </light-box>
        </v-row>
        <v-row v-if="media.hasSample && imageSample.isProcessing">
            <v-col cols="12">
                <v-card flat>
                    <v-card-title class="primary--text text-h1 text-center justify-center">Image is processing!
                    </v-card-title>
                    <svg height="300"
                         preserveAspectRatio="xMidYMid"
                         style="margin:auto;background:transparent;display:block;" viewBox="0 0 100 100"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g transform="translate(50 50)">
                            <g transform="translate(-19 -19) scale(0.6)">
                                <g>
                                    <animateTransform attributeName="transform" begin="0s"
                                                      dur="0.3333333333333333s" keyTimes="0;1"
                                                      repeatCount="indefinite" type="rotate"
                                                      values="0;45"></animateTransform>
                                    <path d="M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                          fill="#febe1e"></path>
                                </g>
                            </g>
                            <g transform="translate(19 19) scale(0.6)">
                                <g>
                                    <animateTransform attributeName="transform" begin="-0.16666666666666666s"
                                                      dur="0.3333333333333333s" keyTimes="0;1"
                                                      repeatCount="indefinite"
                                                      type="rotate"
                                                      values="45;0"></animateTransform>
                                    <path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                          fill="#22bfcd"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!media.hasSample">
            <v-col cols="12">
                <div class="sample-placeholder">
                    <h1 class="mb-3">This image has not been processed yet!</h1>
                    <h2 class="mb-3">You can process this image by clicking this button</h2>
                    <v-btn :loading="isBusy" color="primary" dark large rounded @click="onProcessImage">
                        <v-icon class="mr-2">mdi-cog</v-icon>
                        Process image
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LightBox from "@/components/LightBox/LightBox";
import { getUrlPath } from "@/util";

export default {
  name: "AiVisionTab",
  components: {
    LightBox,
  },
  data() {
    return {
      gallery: [],
      titleItems: {
        inputImageThumb: "Source Image",
        outputImageRawThumb: "Raw Image",
        outputImageHeatmapThumb: "Heat Map",
        outputImageGazeplotThumb: "Gaze Plot",
        outputImageOpacityThumb: "Image Opacity",
        outputImageAoiThumb: "Areas Of Interest",
        outputImageAesThumb: "Aesthetics",
      },
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      project: "getProject",
      media: "getMedia",
      imageSample: "getImageSample",
    }),
  },
  methods: {
    onProcessImage() {
      this.isBusy = true;
      this.createImageSample(this.media.uuid)
          .finally(() => {
            this.isBusy = false;
          });
    },
    setImageSlides() {
      this.gallery.splice(0, this.gallery.length);
      this.gallery.push({
        src: this.imageSample.inputImage,
        download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.imageSample.inputImage))}`,
        thumb: this.imageSample.inputImageThumb,
        caption: "Original Image",
      });
      this.gallery.push({
        src: this.imageSample.combinedImageHeatmap,
        download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.imageSample.combinedImageHeatmap))}`,
        thumb: this.imageSample.outputImageHeatmapThumb,
        caption: "Heat Map",
        backgroundImageThumb: this.imageSample.inputImageThumb,
      });
      this.gallery.push({
        src: this.imageSample.combinedImageGazeplot,
        download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.imageSample.combinedImageGazeplot))}`,
        thumb: this.imageSample.outputImageGazeplotThumb,
        caption: "Gaze Plot",
        backgroundImage: this.imageSample.inputImage,
        backgroundImageThumb: this.imageSample.inputImageThumb,
      });
      this.gallery.push({
        src: this.imageSample.combinedImageOpacity,
        download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.imageSample.combinedImageOpacity))}`,
        thumb: this.imageSample.outputImageOpacityThumb,
        caption: "Opacity Map",
      });
      if (this.imageSample.outputImageAes) {
        this.gallery.push({
          src: this.imageSample.outputImageAes,
          download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(this.imageSample.outputImageAes))}`,
          thumb: this.imageSample.outputImageAesThumb,
          caption: "Aesthetics Report",
        });
      }
    },
    onGalleryShow(index) {
      this.$refs.lightbox.showImage(index);
    },
    ...mapMutations(["setMedia"]),
    ...mapActions(["createImageSample"]),
  },
  mounted() {
    this.setImageSlides();
  },
};
</script>

<style lang="scss">

.sample-placeholder {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  border: 2px dashed #363636;
  justify-content: center;
  align-items: center;

  h1, h2 {
    color: #363636;
    opacity: 0.8;
  }
}

</style>
