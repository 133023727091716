export default function createSocketPlugin(socket) {
  return (store) => {

    socket.on("image-attention status", function ({ mediaUuid, imageAttention, status, error }) {
      if (status === "completed") {
        if (imageAttention.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `Media ${ mediaUuid } process complete`,
            type: "success",
            timeout: 5000,
            dismissible: true,
          });
        }
        store.commit("maybeUpdateSample", { mediaUuid, imageAttention });
        store.commit("updateMediaRefreshKey");
      }
      if (status === "failed") {
        if (imageAttention.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `Media ${ mediaUuid } process failed: ${ error.message }`,
            type: "error",
            timeout: 0,
            dismissible: true,
          });
        }
        store.commit("maybeDeleteSample", mediaUuid);
        store.commit("updateMediaRefreshKey");
      }
    });
    socket.on("image-aoi status", function ({ status, imageAoi, mediaUuid, error }) {
      if (status === "completed") {
        if (imageAoi.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `Areas analysis for media ${ mediaUuid } process complete`,
            type: "success",
            timeout: 5000,
            dismissible: true,
          });
        }
        store.commit("maybeUpdateAoi", { imageAoi, mediaUuid });
        store.commit("updateMediaRefreshKey");
      }
      if (status === "failed") {
        if (imageAoi.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `Areas analysis for media ${ mediaUuid } process failed: ${ error.message }`,
            type: "error",
            timeout: 0,
            dismissible: true,
          });
        }
        store.commit("maybeDeleteAoi", { mediaUuid, imageAoi });
        store.commit("updateMediaRefreshKey");
      }
    });

    socket.on("image-abc status", function ({ status, imageAbc, mediaUuid, error }) {
      if (status === "completed") {
        if (imageAbc.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `ABC-analysis for media ${ mediaUuid } process complete`,
            type: "success",
            timeout: 5000,
            dismissible: true,
          });
        }
        store.commit("maybeUpdateAbc", { imageAbc, mediaUuid });
        store.commit("updateMediaRefreshKey");
      }
      if (status === "failed") {
        if (imageAbc.ownerUuid === store.getters.authUserId) {
          store.commit("addAlert", {
            message: `ABC-analysis for media ${ mediaUuid } process failed: ${ error.message }`,
            type: "error",
            timeout: 0,
            dismissible: true,
          });
        }
        store.commit("maybeDeleteAbc", mediaUuid);
        store.commit("updateMediaRefreshKey");
      }
    });
  };
}
