var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area-editor"},[_c('v-toolbar',{staticClass:"area-editor__toolbar",attrs:{"elevation":"0"}},[_c('v-btn-toggle',{attrs:{"shaped":""},model:{value:(_vm.toolMode),callback:function ($$v) {_vm.toolMode=$$v},expression:"toolMode"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("mdi-pencil-box-multiple-outline")])],1)]}}])},[_c('span',[_vm._v("Draw rectangle area")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.rectArray.length}},on),[_c('v-icon',[_vm._v("mdi-arrow-all")])],1)]}}])},[_c('span',[_vm._v("Move/resize area")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.rectArray.length>0}},on),[_c('v-icon',[_vm._v("mdi-eraser")])],1)]}}])},[_c('span',[_vm._v("Remove area from image")])])],1),_vm._t("prepend"),_c('v-slider',{staticClass:"flex-grow-1",attrs:{"hide-details":"","max":"100","min":"1","thumb-label":"always","thumb-size":"32"},on:{"input":_vm.onZoom},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":_vm.onZoomIn}},on),[_c('v-icon',[_vm._v("mdi-magnify-plus")])],1)]}}])},[_c('span',[_vm._v("Zoom in")])])]},proxy:true},{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":_vm.onZoomOut}},on),[_c('v-icon',[_vm._v("mdi-magnify-minus")])],1)]}}])},[_c('span',[_vm._v("Zoom Out")])])]},proxy:true},{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+"% ")]}}]),model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}}),_vm._t("append")],2),_c('svg',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetSelection),expression:"resetSelection"}],ref:"svg-canvas",staticClass:"svg-canvas",style:({cursor: _vm.getCursorType, width:(_vm.zoom + "%")}),attrs:{"viewBox":("0 0 " + _vm.width + " " + _vm.height)},on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.onMouseDown.apply(null, arguments)},"mouseup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.onMouseUp.apply(null, arguments)},"mousemove":function($event){$event.preventDefault();return _vm.onMouseMove.apply(null, arguments)}}},[_c('image',{attrs:{"href":_vm.image}}),_vm._l((_vm.rectArray),function(ref,index){
var x = ref.x;
var y = ref.y;
var width = ref.width;
var height = ref.height;
return _c('g',{key:("rect-" + index),class:{'selected':_vm.currentIndex===index, 'hoverable': _vm.toolMode===2},attrs:{"data-index":index,"vector-effect":"non-scaling-size"},on:{"click":function($event){return _vm.onClickRect(index)}}},[_c('rect',{staticClass:"rect-area draggable",style:({cursor:_vm.isHandToolMode?(_vm.isDragging ? 'grabbing':'grab'):'default'}),attrs:{"height":height,"width":width,"x":x,"y":y}}),_c('text',{staticClass:"draggable",style:({cursor:_vm.isHandToolMode?(_vm.isDragging ? 'grabbing':'grab'):'default'}),attrs:{"font-size":width*0.2,"x":x+0.5*width,"y":y+0.5*height}},[_vm._v("Area "+_vm._s(index)+" ")]),_c('circle',{staticClass:"top-left resizable",attrs:{"cx":x,"cy":y,"r":_vm.radiusScale,"data-anchor":"0"}}),_c('circle',{staticClass:"top-center resizable",attrs:{"cx":x + 0.5*width,"cy":y,"r":_vm.radiusScale,"data-anchor":"1"}}),_c('circle',{staticClass:"top-right resizable",attrs:{"cx":x + width,"cy":y,"r":_vm.radiusScale,"data-anchor":"2"}}),_c('circle',{staticClass:"center-right resizable",attrs:{"cx":x + width,"cy":y+0.5*height,"r":_vm.radiusScale,"data-anchor":"3"}}),_c('circle',{staticClass:"bottom-right resizable",attrs:{"cx":x + width,"cy":y+height,"r":_vm.radiusScale,"data-anchor":"4"}}),_c('circle',{staticClass:"bottom-center resizable",attrs:{"cx":x + 0.5*width,"cy":y+height,"r":_vm.radiusScale,"data-anchor":"5"}}),_c('circle',{staticClass:"bottom-left resizable",attrs:{"cx":x,"cy":y+height,"r":_vm.radiusScale,"data-anchor":"6"}}),_c('circle',{staticClass:"center-left resizable",attrs:{"cx":x,"cy":y+0.5*height,"r":_vm.radiusScale,"data-anchor":"7"}})])})],2),_c('v-overlay',{attrs:{"value":_vm.isLoading,"absolute":"","color":"white","opacity":"0.8"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }