import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import projects from "./modules/projects";
import media from "./modules/media";
import users from "./modules/users";
import companies from "./modules/companies";
import transactions from "./modules/transactions";
import settings from "./modules/settings";

import { socket } from "@/api";
import createSocketPlugin from "@/store/plugins/socketPlugin";

Vue.use(Vuex);

const uid = (Math.random() + 1).toString(36).substring(7);

export default new Vuex.Store({
  plugins: [createSocketPlugin(socket)],
  state: {
    alertPool: [],
    alertIndex: 0,
    confirm: {
      title: "",
      description: "",
      yesButton: "",
      noButton: "",
      visibility: false,
      onYes: function () {
      },
      onNo: function () {
      },
    },
    prompt: {
      title: "",
      description: "",
      applyButton: "Apply",
      closeButton: "Cancel",
      value: "",
      label: "Enter a value",
      visibility: false,
      onInput: function () {
      },
    },
  },
  getters: {
    getAlertPool: ({ alertPool }) => alertPool,
    getConfirm: ({ confirm }) => confirm,
    getPrompt: ({ prompt }) => prompt,
  },
  mutations: {
    addAlert(state, { message, type, timeout, dismissible }) {
      state.alertPool.push({ message, type, timeout, dismissible, key: `${ uid }-${ ++state.alertIndex }` });
    },
    setConfirm({ confirm }, { title, description, yesButton, noButton }) {
      confirm.title = title;
      confirm.description = description;
      confirm.yesButton = yesButton;
      confirm.noButton = noButton;
    },
    setPrompt({ prompt }, { title, description, applyButton, value, label }) {
      prompt.title = title;
      prompt.description = description;
      prompt.applyButton = applyButton;
      prompt.value = value;
      prompt.label = label;
    },
    confirmShow({ confirm }) {
      confirm.visibility = true;
    },
    confirmHide({ confirm }) {
      confirm.visibility = false;
    },
    promptShow({ prompt }) {
      prompt.visibility = true;
    },
    promptHide({ prompt }) {
      prompt.visibility = false;
    },
    confirmSetHandlers({ confirm }, { yesCallback, noCallback }) {
      confirm.onYes = yesCallback;
      confirm.onNo = noCallback;
    },
    promptSetHandler({ prompt }, callback) {
      prompt.onInput = callback;
    },
  },
  modules: {
    auth,
    projects,
    media,
    users,
    companies,
    transactions,
    settings,
  },
});
