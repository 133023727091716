import store from "@/store";

class Confirm {
  constructor(fn) {
    this.resolver = this.resolver.bind(this);
    this.rejector = this.rejector.bind(this);
    this.yesFn = null;
    this.noFn = null;
    fn(this.resolver, this.rejector);
  }

  resolver() {
    this.yesFn && this.yesFn();
  }

  rejector() {
    this.noFn && this.noFn();
  }

  yes(yesFn) {
    this.yesFn = yesFn;
    return this;
  }

  no(noFn) {
    this.noFn = noFn;
    return this;
  }
}

class Prompt {
  constructor(fn) {
    this.resolver = this.resolver.bind(this);
    this.applyFn = null;
    fn(this.resolver);
  }

  resolver(value) {
    this.applyFn && this.applyFn(value);
  }

  apply(applyFn) {
    this.applyFn = applyFn;
    return this;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$confirm = (title, description, yesButton = "yes", noButton = "no") => {
      store.commit("setConfirm", { title, description, yesButton, noButton });
      store.commit("confirmShow");
      return new Confirm((yes, no) => {
        store.commit("confirmSetHandlers", { yesCallback: yes, noCallback: no });
        store.commit("confirmShow");
      });
    };
    Vue.prototype.$prompt = (title, description, value = "", label = "Enter value", applyButton = "Accept") => {
      store.commit("setPrompt", { title, description, applyButton, value, label });
      store.commit("promptShow");
      return new Prompt((apply) => {
        store.commit("promptSetHandler", apply);
        store.commit("promptShow");
      });
    };
  },
};
