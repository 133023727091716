<template>
    <div class="area-editor">
        <v-toolbar class="area-editor__toolbar" elevation="0">
            <v-btn-toggle v-model="toolMode" shaped>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on}">
                        <v-btn v-on="on">
                            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Draw rectangle area</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :disabled="!rectArray.length">
                            <v-icon>mdi-arrow-all</v-icon>
                        </v-btn>
                    </template>
                    <span>Move/resize area</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :disabled="!rectArray.length>0">
                            <v-icon>mdi-eraser</v-icon>
                        </v-btn>
                    </template>
                    <span>Remove area from image</span>
                </v-tooltip>
            </v-btn-toggle>
            <slot name="prepend">
            </slot>
            <v-slider
                    v-model="zoom"
                    class="flex-grow-1"
                    hide-details
                    max="100"
                    min="1"
                    thumb-label="always"
                    thumb-size="32"
                    @input="onZoom"
            >
                <template v-slot:append>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" icon small @click="onZoomIn">
                                <v-icon>mdi-magnify-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Zoom in</span>
                    </v-tooltip>
                </template>
                <template v-slot:prepend>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" icon small @click="onZoomOut">
                                <v-icon>mdi-magnify-minus</v-icon>
                            </v-btn>
                        </template>
                        <span>Zoom Out</span>
                    </v-tooltip>
                </template>
                <template v-slot:thumb-label="{value}">
                    {{ value }}%
                </template>
            </v-slider>
            <slot name="append">
            </slot>
        </v-toolbar>
        <svg ref="svg-canvas" v-click-outside="resetSelection" :style="{cursor: getCursorType, width:`${zoom}%`}"
             :viewBox="`0 0 ${width} ${height}`"
             class="svg-canvas"
             @mousedown.left.prevent="onMouseDown" @mouseup.left.prevent="onMouseUp" @mousemove.prevent="onMouseMove">
            <image :href="image"/>
            <g v-for="({x,y,width,height},index) in rectArray" :key="`rect-${index}`"
               :class="{'selected':currentIndex===index, 'hoverable': toolMode===2}"
               :data-index="index" vector-effect="non-scaling-size" @click="onClickRect(index)">
                <rect :height="height" :style="{cursor:isHandToolMode?(isDragging ? 'grabbing':'grab'):'default'}"
                      :width="width" :x="x" :y="y" class="rect-area draggable"/>
                <text :font-size="width*0.2" :style="{cursor:isHandToolMode?(isDragging ? 'grabbing':'grab'):'default'}"
                      :x="x+0.5*width" :y="y+0.5*height"
                      class="draggable">Area {{ index }}
                </text>
                <circle :cx="x" :cy="y" :r="radiusScale" class="top-left resizable" data-anchor="0"></circle>
                <circle :cx="x + 0.5*width" :cy="y" :r="radiusScale" class="top-center resizable"
                        data-anchor="1"></circle>
                <circle :cx="x + width" :cy="y" :r="radiusScale" class="top-right resizable" data-anchor="2"></circle>
                <circle :cx="x + width" :cy="y+0.5*height" :r="radiusScale" class="center-right resizable"
                        data-anchor="3"></circle>
                <circle :cx="x + width" :cy="y+height" :r="radiusScale" class="bottom-right resizable"
                        data-anchor="4"></circle>
                <circle :cx="x + 0.5*width" :cy="y+height" :r="radiusScale" class="bottom-center resizable"
                        data-anchor="5"></circle>
                <circle :cx="x" :cy="y+height" :r="radiusScale" class="bottom-left resizable" data-anchor="6"></circle>
                <circle :cx="x" :cy="y+0.5*height" :r="radiusScale" class="center-left resizable"
                        data-anchor="7"></circle>
            </g>
        </svg>
        <v-overlay :value="isLoading" absolute color="white" opacity="0.8">
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { screenToSVG, rectContains } from "@/util";

export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    areaString: {
      type: String,
      default() {
        return "Area";
      },
    },
    isLoading: {
      type: Boolean,
    },
  },
  name: "AreaEditor",
  data() {
    return {
      currentIndex: -1,
      radiusScale: 0,
      initialPoint: { x: 0, y: 0 },
      previousPoint: { x: 0, y: 0 },
      initialShape: { x: 0, y: 0, width: 0, height: 0 },
      newShape: { x: 0, y: 0, width: 0, height: 0 },
      currentAnchor: 0,
      svgRect: null,
      selectedGroup: null,
      rectArray: this.value,
      width: 0,
      height: 0,
      zoom: 100,
      isDrawing: false,
      isDragging: false,
      isResizing: false,
      toolMode: 0,
    };
  },
  computed: {
    getCursorType() {
      if (this.isRectDrawMode)
        return "crosshair";
      return "default";
    },
    isRectDrawMode() {
      return this.toolMode === 0;
    },
    isHandToolMode() {
      return this.toolMode === 1;
    },
  },
  methods: {
    onZoom() {
      const ctm = this.$refs["svg-canvas"].getScreenCTM();
      if (ctm) {
        this.radiusScale = 4 / ctm.a;
      }
    },
    onZoomIn() {
      this.zoom = this.zoom + 10 > 100 ? 100 : this.zoom + 10;
      this.onZoom();
    },
    onZoomOut() {
      this.zoom = this.zoom - 10 < 1 ? 1 : this.zoom - 10;
      this.onZoom();
    },
    onClickRect(index) {
      if (this.toolMode === 2) {
        this.onDeleteArea(index);
      }
    },
    onDeleteArea(currentIndex) {
      this.$confirm(`Remove ${ this.areaString } ${ currentIndex }?`, "Are you sure you want to remove this area?")
          .yes(() => {
            this.rectArray.splice(currentIndex, 1);
            this.resetSelection();
            if (!this.rectArray.length) {
              this.toolMode = null;
            }
          });
    },
    resetSelection() {
      this.currentIndex = -1;
    },
    normalizeRectangle(x, y, width, height) {
      return {
        width: width > 0 ? width : -width,
        height: height > 0 ? height : -height,
        x: width > 0 ? x : x + width,
        y: height > 0 ? y : y + height,
      };
    },
    createRectangle(x, y, width, height) {
      const svgRectangle = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      svgRectangle.x.baseVal.value = x;
      svgRectangle.y.baseVal.value = y;
      svgRectangle.width.baseVal.value = width;
      svgRectangle.height.baseVal.value = height;
      return svgRectangle;
    },
    drawRectangle(x, y) {
      const p = screenToSVG(this.$refs["svg-canvas"], x, y);
      const dx = p.x - this.initialPoint.x;
      const dy = p.y - this.initialPoint.y;
      this.svgRect.x.baseVal.value = dx > 0 ? this.initialPoint.x : p.x;
      this.svgRect.y.baseVal.value = dy > 0 ? this.initialPoint.y : p.y;
      this.svgRect.width.baseVal.value = dx > 0 ? dx : -dx;
      this.svgRect.height.baseVal.value = dy > 0 ? dy : -dy;
    },
    scaleRectangleByAnchorOffset(dx, dy, anchor) {
      const anchorWHMatrix = [
        { x: -1, y: -1 },
        { x: 0, y: -1 },
        { x: 1, y: -1 },
        { x: 1, y: 0 },
        { x: 1, y: 1 },
        { x: 0, y: 1 },
        { x: -1, y: 1 },
        { x: -1, y: 0 },
      ];
      const anchorXYMatrix = [
        { x: 1, y: 1 },
        { x: 0, y: 1 },
        { x: 0, y: 1 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 1, y: 0 },
      ];
      this.newShape.width = this.initialShape.width + anchorWHMatrix[anchor].x * dx;
      this.newShape.height = this.initialShape.height + anchorWHMatrix[anchor].y * dy;
      this.newShape.x = this.initialShape.x + anchorXYMatrix[anchor].x * dx;
      this.newShape.y = this.initialShape.y + anchorXYMatrix[anchor].y * dy;
    },
    scaleGroupByAnchorOffset(dx, dy, anchor) {
      const anchorPositions = [
        { x: 0, y: 0 },
        { x: 0.5, y: 0 },
        { x: 1, y: 0 },
        { x: 1, y: 0.5 },
        { x: 1, y: 1 },
        { x: 0.5, y: 1 },
        { x: 0, y: 1 },
        { x: 0, y: 0.5 },
      ];
      let ci = 0;
      for (const child of this.selectedGroup.children) {
        if (child.tagName === "rect") {
          this.scaleRectangleByAnchorOffset(dx, dy, anchor);
          const {
            x,
            y,
            width,
            height,
          } = this.normalizeRectangle(this.newShape.x, this.newShape.y, this.newShape.width, this.newShape.height);
          child.x.baseVal.value = x;
          child.y.baseVal.value = y;
          child.width.baseVal.value = width;
          child.height.baseVal.value = height;
        }
        if (child.tagName === "circle") {
          child.cx.baseVal.value = this.newShape.x + this.newShape.width * anchorPositions[ci].x;
          child.cy.baseVal.value = this.newShape.y + this.newShape.height * anchorPositions[ci].y;
          ci++;
        }
        if (child.tagName === "text") {
          child.x.baseVal[0].value = this.newShape.x + this.newShape.width * 0.5;
          child.y.baseVal[0].value = this.newShape.y + this.newShape.height * 0.5;
          child.style.fontSize = (this.newShape.width > 0 ? this.newShape.width * 0.2 : -0.2 * this.newShape.width) + "px";
        }
      }
    },
    moveGroup(dx, dy) {
      for (const child of this.selectedGroup.children) {
        if (child.tagName === "rect") {
          child.x.baseVal.value += dx;
          child.y.baseVal.value += dy;
        }
        if (child.tagName === "circle") {
          child.cx.baseVal.value += dx;
          child.cy.baseVal.value += dy;
        }
        if (child.tagName === "text") {
          child.x.baseVal[0].value += dx;
          child.y.baseVal[0].value += dy;
        }
      }
    },
    onMouseDown(evt) {
      if (!this.radiusScale) {
        this.onZoom();
      }
      const { x, y } = evt;
      this.resetSelection();
      if (this.isRectDrawMode || this.isHandToolMode) {
        const p = screenToSVG(this.$refs["svg-canvas"], x, y);
        this.initialPoint.x = p.x;
        this.initialPoint.y = p.y;
      }
      if (this.isRectDrawMode) {
        this.isDrawing = true;
        this.svgRect = this.createRectangle(this.initialPoint.x, this.initialPoint.y, 1, 1);
        this.$refs["svg-canvas"].appendChild(this.svgRect);
      }
      if (this.isHandToolMode && evt.target.classList.contains("draggable")) {
        this.selectedGroup = evt.target.parentElement;
        this.currentIndex = parseInt(this.selectedGroup.dataset.index);
        this.isDragging = true;
        this.previousPoint.x = this.initialPoint.x;
        this.previousPoint.y = this.initialPoint.y;
      }
      if (this.isHandToolMode && evt.target.classList.contains("resizable")) {
        this.selectedGroup = evt.target.parentElement;
        this.currentIndex = parseInt(this.selectedGroup.dataset.index);
        this.currentAnchor = parseInt(evt.target.dataset.anchor);
        this.isResizing = true;
        this.initialShape.x = this.rectArray[this.currentIndex].x;
        this.initialShape.y = this.rectArray[this.currentIndex].y;
        this.initialShape.width = this.rectArray[this.currentIndex].width;
        this.initialShape.height = this.rectArray[this.currentIndex].height;
      }
    },
    onMouseMove(evt) {
      const { x, y } = evt;
      if (this.isDrawing) {
        this.drawRectangle(x, y);
      }
      if (this.isDragging) {
        const p = screenToSVG(this.$refs["svg-canvas"], x, y);
        const dx = p.x - this.previousPoint.x;
        const dy = p.y - this.previousPoint.y;
        this.moveGroup(dx, dy);
        this.previousPoint.y += dy;
        this.previousPoint.x += dx;
      }
      if (this.isResizing) {
        const p = screenToSVG(this.$refs["svg-canvas"], x, y);
        const dx = p.x - this.initialPoint.x;
        const dy = p.y - this.initialPoint.y;
        this.scaleGroupByAnchorOffset(dx, dy, this.currentAnchor);
      }
    },
    onMouseUp(evt) {
      const { x, y } = evt;
      if (evt.button !== 0 || !(this.isDrawing || this.isDragging || this.isResizing)) {
        return;
      }
      const isOnCanvas = rectContains(this.$refs["svg-canvas"].getBoundingClientRect(), { x, y });
      if (this.isDrawing) {
        if (isOnCanvas) {
          this.drawRectangle(x, y);
        }
        this.isDrawing = false;
        if (this.svgRect.width.baseVal.value >= 5 && this.svgRect.height.baseVal.value) {
          this.rectArray.push({
            x: this.svgRect.x.baseVal.value,
            y: this.svgRect.y.baseVal.value,
            width: this.svgRect.width.baseVal.value,
            height: this.svgRect.height.baseVal.value,
          });
          this.$emit("input", this.rectArray);
        }
        this.svgRect.remove();
        this.svgRect = null;
      }
      if (this.isDragging) {
        if (isOnCanvas) {
          const p = screenToSVG(this.$refs["svg-canvas"], x, y);
          const dx = p.x - this.initialPoint.x;
          const dy = p.y - this.initialPoint.y;
          this.rectArray[this.currentIndex].x += dx;
          this.rectArray[this.currentIndex].y += dy;
        } else {
          this.rectArray[this.currentIndex].x += this.previousPoint.x - this.initialPoint.x;
          this.rectArray[this.currentIndex].y += this.previousPoint.y - this.initialPoint.y;
        }
        this.$emit("input", this.rectArray);
        this.isDragging = false;
      }
      if (this.isResizing) {
        if (isOnCanvas) {
          const p = screenToSVG(this.$refs["svg-canvas"], x, y);
          const dx = p.x - this.initialPoint.x;
          const dy = p.y - this.initialPoint.y;
          this.scaleGroupByAnchorOffset(dx, dy, this.currentAnchor);
        }
        const normalizedRect = this.normalizeRectangle(this.newShape.x, this.newShape.y, this.newShape.width, this.newShape.height);
        this.rectArray[this.currentIndex].x = normalizedRect.x;
        this.rectArray[this.currentIndex].y = normalizedRect.y;
        this.rectArray[this.currentIndex].width = normalizedRect.width;
        this.rectArray[this.currentIndex].height = normalizedRect.height;
        this.$emit("input", this.rectArray);
        this.isResizing = false;
      }
    },
  },
  created() {
    const image = new Image();
    image.onload = () => {
      this.height = image.height;
      this.width = image.width;
    };
    image.src = this.image;
    document.addEventListener("mouseup", this.onMouseUp);
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.onMouseUp);
  },
};
</script>

<style lang="scss">
.area-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: linear-gradient(-90deg, rgba(0, 0, 0, .05) 1px, transparent 1px),
  linear-gradient(rgba(0, 0, 0, .05) 1px, transparent 1px),
  linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
  linear-gradient(rgba(0, 0, 0, .04) 1px, transparent 1px),
  linear-gradient(transparent 19px, #f2f2f2 19px, #f2f2f2 98px, transparent 98px),
  linear-gradient(-90deg, #aaa 1px, transparent 1px),
  linear-gradient(-90deg, transparent 19px, #f2f2f2 19px, #f2f2f2 98px, transparent 98px),
  linear-gradient(#aaa 1px, transparent 1px),
  #f2f2f2;
  background-size: 20px 20px,
  20px 20px,
  100px 100px,
  100px 100px,
  100px 100px,
  100px 100px,
  100px 100px,
  100px 100px;

  &__toolbar {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    .v-toolbar__content {
      flex-grow: 1;
    }
  }

  svg.svg-canvas {
    rect {
      stroke: #0e7680;
      fill: rgba(22, 181, 196, 0.5);
      stroke-width: 4;
      vector-effect: non-scaling-stroke;
    }

    circle {
      fill: #d9a422;
      visibility: hidden;
      stroke-width: 4;
      vector-effect: non-scaling-stroke;
    }

    text {
      text-anchor: middle;
      dominant-baseline: central;
      fill: rgba(255, 255, 255, 0.6);
    }

    g {

      &.hoverable:hover {
        rect {
          stroke: #febe1e;
          fill: rgba(254, 190, 30, 0.5);
        }

        circle {
          visibility: hidden;
        }
      }

      &.selected {
        circle {
          visibility: visible;
          stroke: #0e7680;

          &.top-left {
            cursor: nwse-resize;
          }

          &.top-right {
            cursor: nesw-resize;
          }

          &.top-center {
            cursor: ns-resize;
          }

          &.bottom-left {
            cursor: nesw-resize;
          }

          &.bottom-right {
            cursor: nwse-resize;
          }

          &.bottom-center {
            cursor: ns-resize;
          }

          &.center-left {
            cursor: ew-resize;
          }

          &.center-right {
            cursor: ew-resize;
          }
        }
      }
    }
  }
}
</style>
