<template>
    <v-dialog :value="modalShow" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ userModalTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="model.email"
                                          :error-messages="errors.email"
                                          dense
                                          label="Email*"
                                          placeholder="Email"
                                          @input="resetField('email')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="model.firstName" dense label="First Name"
                                          placeholder="First Name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="model.lastName" dense label="Last Name"
                                          placeholder="Last Name"></v-text-field>
                        </v-col>
                        <v-col v-if="isCreating" cols="12">
                            <password-input v-model="model.password" :error-messages="errors.password" dense
                                            label="Password*" @input="resetField('password')"></password-input>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="closeUserModal">
                    Close
                </v-btn>
                <v-btn color="primary" text @click="onCreateOrUpdateSubmit">
                    {{ userModalSubmitButton }}
                </v-btn>
            </v-card-actions>
            <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
                <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { filterObjectForbidden } from "@/util";
import formMixin from "@/mixins/form-mixin";
import PasswordInput from "@/components/Inputs/PasswordInput";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserModal",
  mixins: [formMixin],
  components: {
    PasswordInput,
  },
  data() {
    return {
      isBusy: false,
      isCreating: false,
      isEditing: false,
      model: {
        uuid: null,
        email: "",
        firstName: "",
        lastName: "",
        role: "USER",
        companyUuid: null,
        password: "",
      },
    };
  },
  computed: {
    userModalSubmitButton() {
      return this.isCreating ? "Create user" : "Update user";
    },
    userModalTitle() {
      return this.isCreating ? "Create user" : "Update user";
    },
    modalShow() {
      return this.isCreating || this.isEditing;
    },
    ...mapGetters({
      companies: "getCompaniesOptions",
      roleOptions: "getRoleOptions",
    }),
  },
  methods: {
    closeUserModal() {
      this.isCreating = false;
      this.isEditing = false;
    },
    onEditUser({ uuid, email, firstName, lastName, role, companyUuid }) {
      this.resetForm();
      this.model.role = role;
      this.model.uuid = uuid;
      this.model.email = email;
      this.model.firstName = firstName;
      this.model.lastName = lastName;
      this.model.companyUuid = companyUuid;
      this.isEditing = true;
      this.isCreating = false;
    },
    onCreateUser() {
      this.resetForm();
      this.model.role = "USER";
      this.model.uuid = null;
      this.model.companyUuid = null;
      this.model.email = "";
      this.model.firstName = "";
      this.model.lastName = "";
      this.model.password = "";
      this.isEditing = false;
      this.isCreating = true;
    },
    onCreateOrUpdateSubmit() {
      if (this.isEditing) {
        this.onEditUserSubmit();
      } else {
        this.onCreateUserSubmit();
      }
    },
    onCreateUserSubmit() {
      this.isBusy = true;
      this.createUser(filterObjectForbidden(this.model, ["uuid"]))
          .then((user) => {
            this.$success(`User account ${ user.email } was created successfully`);
            this.isCreating = false;
            this.$emit("update");
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    onEditUserSubmit() {
      this.isBusy = true;
      this.updateUser(filterObjectForbidden(this.model, ["password"]))
          .then((user) => {
            this.$success(`User info for ${ user.email } was updated successfully`);
            this.isEditing = false;
            this.$emit("update");
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    ...mapActions(["updateUser", "createUser"]),
  },
  created() {
    this.$eventBus.$on("user:create", this.onCreateUser);
    this.$eventBus.$on("user:edit", this.onEditUser);
  },
  beforeDestroy() {
    this.$eventBus.$off("user:create", this.onCreateUser);
    this.$eventBus.$off("user:edit", this.onEditUser);
  },
};
</script>
