<template>
    <v-sheet elevation="4">
        <v-data-table
                :footer-props="{'items-per-page-options': [10,25,50], 'show-current-page': true, 'show-first-last-page': true}"
                :headers="headers"
                :items="users"
                :loading="isLoading"
                :options.sync="options"
                :server-items-length="total"
                item-key="uuid"
                sort-by="createdAt"
        >
            <template v-slot:top>
                <v-toolbar flat height="auto">
                    <v-row align="center" justify="center" no-gutters>
                        <v-col class="d-flex justify-center align-center" cols="12" style="height: 64px;">
                            <v-toolbar-title class="mr-4 d-flex justify-center align-center">Users</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="$eventBus.$emit('user:create')">New User</v-btn>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" xl="3">
                            <v-text-field append-icon="mdi-magnify" class="mr-4" dense filled hide-details
                                          label="Search">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" xl="3">
                            <v-select v-model="filter.role" :items="roleOptions" class="mr-4"
                                      clearable
                                      dense
                                      filled hide-details
                                      label="Filter by role"
                                      multiple
                                      @change="$emit('update');"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" xl="4">
                            <v-select v-model="filter.companyUuid" :items="companies" clearable dense
                                      filled
                                      hide-details label="Filter by company"
                                      multiple
                                      small-chips
                                      @change="$emit('update');"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="2" md="4" xl="2">
                            <v-btn v-if="filter.role.length || filter.companyUuid.length" class="ml-2" color="default"
                                   text @click="onFilterReset">
                                <v-icon>mdi-filter-remove-outline</v-icon>
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.createdAt="{ value }">
                {{ dateFormat(value) }}
            </template>
            <template v-slot:item.companyUuid="{ item }">
                {{ companyName(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-4" color="primary" @click="$eventBus.$emit('user:edit',item)">
                    mdi-pencil
                </v-icon>
                <v-icon class="mr-4" color="warning" @click="$eventBus.$emit('user:password',item)">
                    mdi-form-textbox-password
                </v-icon>
                <v-icon color="error" @click="onDeleteUser(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dateFormat } from "@/util";

export default {
  name: "UsersTable",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    headers: [
      { text: "Email", align: "start", value: "email", sortable: true, filterable: true },
      { text: "First Name", align: "start", value: "firstName", sortable: true, filterable: true },
      { text: "Last Name", align: "start", value: "lastName", sortable: true, filterable: true },
      { text: "Company", align: "start", value: "companyUuid", sortable: true, filterable: true },
      { text: "Role", align: "start", value: "role", sortable: true, filterable: true },
      { text: "Registered", align: "center", value: "createdAt", sortable: true, filterable: true },
      { text: "Actions", align: "center", value: "actions", sortable: false },
    ],
    options: null,
    dateFormat,
  }),
  computed: {
    ...mapGetters({
      users: "getUsersList",
      page: "getUsersPage",
      total: "getUsersTotal",
      limit: "getUsersLimit",
      companies: "getCompaniesOptions",
      sortDirection: "getUsersSortDirection",
      sort: "getUsersSort",
      filter: "getUsersFilter",
      roleOptions: "getRoleOptions",
    }),
  },
  watch: {
    options: {
      handler({ itemsPerPage, sortBy, sortDesc, page }, oldValue) {
        if (oldValue) {
          this.setTransactionsPage(page);
          if (sortBy.length) {
            this.setUsersSort({ sort: sortBy[0], sortDirection: sortDesc[0] ? -1 : 1 });
          }
          this.setUsersLimit(itemsPerPage);
          this.$emit("update");
        }
      },
      deep: true,
    },
  },
  methods: {
    onFilterReset() {
      this.setUsersFilter({ role: [], companyUuid: [] });
      this.setUsersPage(1);
      this.options.page = 1;
      this.$emit("update");
    },
    onDeleteUser({ uuid }) {
      this.$confirm("Delete user", "Are you sure you want to remove user")
          .yes(() => {
            this.deleteUser(uuid)
                .then(() => {
                  this.$emit("update");
                })
                .catch(() => {
                });
          });
    },
    companyName({ company }) {
      return company ? company.name : "";
    },
    ...mapMutations(["setUsersSort", "setUsersFilter", "setUsersPage", "setUsersLimit"]),
    ...mapActions(["deleteUser"]),
  },
};
</script>
