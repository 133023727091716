import Vue from "vue";
import { apiClient } from "@/api";
import router from "@/router";
import logger from "@/datadog-logger";

const eventBus = new Vue({
  methods: {
    onApiError({ code, message, className }) {
      if (code == 401) {
        apiClient.logout().then(() => {
          router.push({ name: "Login" });
          this.$error("Session has expired. You need to re-authorize.");
        });
      } else if (className !== "validation-error") {
        this.$error(message);
        logger.error(message);
      }
    },
  },
  created() {
    this.$on("api:error", this.onApiError);
  },
});

export default eventBus;
