import { apiClient } from "@/api";

export default {
  state: {
    userList: [],
    total: 0,
    limit: 10,
    page: 1,
    sort: "createdAt",
    sortDirection: -1,
    filter: {
      role: [],
      companyUuid: [],
    },
    roleOptions: [
      {
        value: "ADMIN",
        text: "Administrator",
      },
      {
        value: "COMPANY",
        text: "Company management",
      },
      {
        value: "USER",
        text: "User of application",
      },
    ],
  },
  getters: {
    getUsersList: ({ userList }) => userList,
    getUsersTotal: ({ total }) => total,
    getUsersLimit: ({ limit }) => limit,
    getUsersSort: ({ sort }) => sort,
    getUsersPage: ({ page }) => page,
    getUsersSortDirection: ({ sortDirection }) => sortDirection,
    getUsersFilter: ({ filter }) => filter,
    getRoleOptions: ({ roleOptions }) => roleOptions,
  },
  mutations: {
    setUsersSort(state, { sort, sortDirection }) {
      state.sort = sort;
      state.sortDirection = sortDirection;
    },
    setUsersLimit(state, newLimit) {
      state.limit = newLimit;
    },
    setUsersFilter({ filter }, { role, companyUuid }) {
      filter.companyUuid.splice(0, filter.companyUuid.length);
      filter.role.splice(0, filter.role.length);
      if (role && role.length) {
        filter.role = [
          ...role,
        ];
      }
      if (companyUuid && companyUuid.length) {
        filter.companyUuid = [
          ...companyUuid,
        ];
      }
    },
    setUsersList(state, newUsersList) {
      state.userList.splice(0, state.userList.length);
      state.userList = [
        ...state.userList,
        ...newUsersList,
      ];
    },
    setUsersPage(state, page) {
      state.page = page;
    },
    invalidateUserList(state) {
      state.userList.splice(0, state.userList.length);
      state.total = 0;
    },
  },
  actions: {
    fetchUserList({ state, commit }) {
      return new Promise((resolve, reject) => {
        const query = {
          $limit: state.limit,
          $skip: (state.page - 1) * state.limit,
          $sort: {
            [state.sort]: state.sortDirection,
          },
        };
        if (state.filter.role.length) {
          query.role = {
            $in: state.filter.role,
          };
        }
        if (state.filter.companyUuid.length) {
          query.companyUuid = {
            $in: state.filter.companyUuid,
          };
        }
        apiClient
          .service("users")
          .find({ query })
          .then(({ total, data }) => {
            state.total = total;
            commit("setUsersList", data);
            return resolve(state.userList);
          })
          .catch(reject);
      });
    },
    createUser(store, newUserData) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("users")
          .create(newUserData)
          .then(resolve)
          .catch(reject);
      });
    },
    updateUser(store, newUserData) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("users")
          .patch(newUserData.uuid, newUserData)
          .then(resolve)
          .catch(reject);
      });
    },
    deleteUser(context, uuid) {
      return apiClient
        .service("users")
        .remove(uuid);
    },
  },
};
