<template>
    <div class="not-found-page">
        <svg class="svg-container" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <text class="drop-shadow" fill="#febe1e" font-size="400" font-weight="bold" x="960"
                  y="400">404
            </text>
            <text class="drop-shadow" fill="#22bfcd" font-size="200" x="960"
                  y="700">Not Found
            </text>
        </svg>
    </div>
</template>
<script>

export default {
  name: "NotFoundPage",
};
</script>
<style lang="scss">
.not-found-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: center / contain no-repeat url("~@/assets/images/bg.svg"),
  #eee 35% url("~@/assets/images/bg.svg");
  background-size: cover;

  .svg-container {
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
    width: 100%;
    height: auto;

    text {
      text-anchor: middle;
      dominant-baseline: central;
      font-family: "Roboto", "Arial", sans-serif;
    }
  }
}
</style>
