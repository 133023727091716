import { datadogLogs } from "@datadog/browser-logs";

const exportObject = {
  logger: {
    log(...args) {
      console.log(...args);
    },
    info(...args) {
      console.info(...args);
    },
    error(...args) {
      console.error(...args);
    },
    debug(...args) {
      console.debug(...args);
    },
    warn(...args) {
      console.warn(...args);
    },
  },
};

if (process.env.VUE_APP_DD_ENV === "staging" || process.env.VUE_APP_DD_ENV === "production") {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DD_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: "design-optics-frontend",
    env: process.env.VUE_APP_DD_ENV,
  });

  datadogLogs.onReady(() => {
    console.info("Datadog is ready");
  });

  exportObject.logger = datadogLogs.logger;
}

export default exportObject.logger;
