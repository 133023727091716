import Vue from "vue";
import Vuetify from "vuetify/lib/framework";


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#22bfcd", // #E53935
        warning: "#febe1e",
        success: "#4CAF50",
        error: "#EF8157",
      },
    },
  },
});
