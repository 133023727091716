<template>
    <v-dialog
            :value="visibility"
            max-width="400"
            persistent
    >
        <v-card>
            <v-card-title class="text-h5">{{ title }}</v-card-title>
            <v-card-text>{{ description }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="onYes">
                    {{ yesButton }}
                </v-btn>
                <v-btn color="primary" @click="onNo">
                    {{ noButton }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    yesButton: {
      type: String,
    },
    noButton: {
      type: String,
    },
  },
  name: "ConfirmDialog",
  methods: {
    onYes() {
      this.$emit("yes");
    },
    onNo() {
      this.$emit("no");
    },
  },
};
</script>

<style scoped>

</style>
