<template>
    <div class="companies-page">
        <companies-table :isLoading="isLoading" @update="syncCompaniesList"></companies-table>
        <company-modal @update="syncCompaniesList"></company-modal>
        <transaction-modal @update="syncCompaniesList"></transaction-modal>
    </div>
</template>

<script>
import CompaniesTable from "@/views/dashboard/Companies/CompaniesTable";
import CompanyModal from "@/views/dashboard/Companies/CompanyModal";
import TransactionModal from "@/views/dashboard/Companies/TransactionModal";
import { mapActions } from "vuex";

export default {
  name: "CompaniesPage",
  components: {
    TransactionModal,
    CompanyModal,
    CompaniesTable,
  },
  data: () => ({
    isLoading: true,
  }),
  methods: {
    syncCompaniesList() {
      this.isLoading = true;
      this.fetchCompaniesList()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    ...mapActions(["fetchCompaniesList"]),
  },
};
</script>

<style lang="scss">

</style>
