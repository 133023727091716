<template>
    <v-card class="image-aoi-card" elevation="4">
        <v-img :alt="area.name"
               :src="!area.isProcessing ? area.outputImageAoiThumb:'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'300\' height=\'300\' viewBox=\'0 0 300 300\'%3E%3Crect fill=\'%23fff\' width=\'300\' height=\'300\'/%3E%3C/svg%3E'"
               class="warning--text align-end text-uppercase"
               contain
               gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.5)"
               lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==">
            <v-card-title v-show="!area.isProcessing" class="text-ellipsis">
                <v-icon class="mr-2" color="warning">mdi-group</v-icon>
                {{ area.name }}
            </v-card-title>
        </v-img>
        <v-fade-transition>
            <v-overlay
                    v-if="area.isProcessing"
                    absolute
                    color="#363636"
            >
                <svg height="200px" preserveAspectRatio="xMidYMid"
                     style="margin:auto;background:transparent;display:block;" viewBox="0 0 100 100" width="200px"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g transform="translate(50 50)">
                        <g transform="translate(-19 -19) scale(0.6)">
                            <g>
                                <animateTransform attributeName="transform" begin="0s" dur="0.3333333333333333s"
                                                  keyTimes="0;1"
                                                  repeatCount="indefinite" type="rotate"
                                                  values="0;45"></animateTransform>
                                <path d="M31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.460477824182675 31.35997276079435 L-21.460477824182675 31.35997276079435 L-28.53154563604815 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                      fill="#febe1e"></path>
                            </g>
                        </g>
                        <g transform="translate(19 19) scale(0.6)">
                            <g>
                                <animateTransform attributeName="transform" begin="-0.16666666666666666s"
                                                  dur="0.3333333333333333s" keyTimes="0;1"
                                                  repeatCount="indefinite" type="rotate"
                                                  values="45;0"></animateTransform>
                                <path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048097 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                      fill="#22bfcd"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <h1 class="warning--text">PROCESSING...</h1>
            </v-overlay>
        </v-fade-transition>
    </v-card>
</template>

<script>
export default {
  props: {
    area: {
      type: Object,
    },
  },
  name: "ImageAoiCard",
};
</script>

<style lang="scss">
.image-aoi-card {
  cursor: pointer;
}
</style>
