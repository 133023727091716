<template>
    <v-row>
        <v-col cols="12" md="6">
            <v-card :loading="isLoading">
                <v-card-title>Feng Credentials</v-card-title>
                <v-card-text>
                    <v-switch v-model="isFengLiveMode" :label="modeString" @change="isDirty=true"></v-switch>
                    <div v-if="isFengLiveMode">
                        <v-text-field v-model="settings.fengLiveApiUser" label="Live Username" type="text"
                                      @input="isDirty=true"></v-text-field>
                        <v-text-field v-model="settings.fengLiveApiPassword" label="Live Password" type="password"
                                      @input="isDirty=true"></v-text-field>
                    </div>
                    <div v-else>
                        <v-text-field v-model="settings.fengTestApiUser" label="Test Username" type="text"
                                      @input="isDirty=true"></v-text-field>
                        <v-text-field v-model="settings.fengTestApiPassword" label="Test Password" type="password"
                                      @input="isDirty=true"></v-text-field>
                    </div>
                    <v-text-field v-model="settings.fengServer" label="Feng Server URL" type="text"
                                  @input="isDirty=true"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="isDirty" color="primary" text @click="onSaveSettings">
                        <v-icon class="mr-2">mdi-check-bold</v-icon>
                        Save Settings
                    </v-btn>
                    <v-btn v-if="isDirty" text @click="reloadSettings">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Discard Settings
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SettingsPage",
  data: () => ({
    isLoading: true,
    isDirty: false,
    isFengLiveMode: false,
  }),
  computed: {
    modeString() {
      return this.isFengLiveMode ? "LIVE" : "TEST";
    },
    ...mapGetters({
      settings: "getSettings",
    }),
  },
  methods: {
    reloadSettings() {
      this.isLoading = true;
      this.fetchSettings()
          .then(() => {
            this.isFengLiveMode = (this.settings.fengMode === "live");
            this.isLoading = false;
            this.isDirty = false;
          })
          .catch(() => {
          });
    },
    onSaveSettings() {
      this.isLoading = true;
      this.updateSettings(this.isFengLiveMode ? {
        fengMode: "live",
        fengLiveApiUser: this.settings.fengLiveApiUser,
        fengLiveApiPassword: this.settings.fengLiveApiPassword,
        fengServer: this.settings.fengServer,
      } : {
        fengMode: "test",
        fengTestApiUser: this.settings.fengTestApiUser,
        fengTestApiPassword: this.settings.fengTestApiPassword,
        fengServer: this.settings.fengServer,
      })
          .then(() => {
            this.isFengLiveMode = (this.settings.fengMode === "live");
            this.isLoading = false;
            this.isDirty = false;
          })
          .catch(() => {
          });
    },
    ...mapActions(["fetchSettings", "updateSettings"]),
  },
  mounted() {
    this.reloadSettings();
  },
};
</script>

<style scoped>

</style>
