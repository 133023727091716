<template>
    <v-sheet elevation="4">
        <v-data-table :headers="headers"
                      :items="companies"
                      :loading="isLoading" disable-filtering disable-pagination
                      hide-default-footer item-key="uuid" sort-by="name">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Companies</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="$eventBus.$emit('company:create')">New Company</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.createdAt="{ value }">
                {{ dateFormat(value) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-4" color="primary" @click="$eventBus.$emit('company:edit', item)">
                    mdi-pencil
                </v-icon>
                <v-icon class="mr-4" color="warning" @click="$eventBus.$emit('transaction:create', item)">
                    mdi-cash-plus
                </v-icon>
                <v-icon color="error" @click="onDeleteCompany(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dateFormat } from "@/util";

export default {
  name: "CompaniesTable",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Users", align: "center", value: "usersCount" },
      { text: "Credits", align: "end", value: "credits" },
      { text: "Created", align: "center", value: "createdAt" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dateFormat,
  }),
  computed: {
    ...mapGetters({
      companies: "getCompaniesList",
    }),
  },
  methods: {
    onDeleteCompany({ uuid }) {
      this.$confirm("Delete company", "Are you sure you want to remove company?")
          .yes(() => {
            this.deleteCompany(uuid)
                .then(() => {
                  this.$emit("update");
                })
                .catch(() => {
                });
          });
    },
    ...mapActions(["deleteCompany"]),
  },
  mounted() {
    this.$emit("update");
  },
};
</script>

<style lang="scss">

</style>
