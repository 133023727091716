<template>
    <div class="areas-of-interest-tab">
        <area-editor v-if="isEditorMode" v-model="rectArray" :image="media.url" :is-loading="isLoading">
            <template v-slot:append>
                <v-btn :disabled="!rectArray.length" class="ml-2" color="primary" @click="onProcess">
                    <v-icon class="mr-2">mdi-check-all</v-icon>
                    Save & Process
                </v-btn>
                <v-btn class="ml-2" color="default" dark>
                    <v-icon class="mr-2" @click="onClose">mdi-close</v-icon>
                    Cancel
                </v-btn>
            </template>
            <template v-slot:prepend>
                <v-text-field v-model="sampleName" class="mx-2 flex-shrink-1 flex-grow-0" dense hide-details
                              label="Output name" outlined placeholder="Output name..."></v-text-field>
            </template>
        </area-editor>
        <v-row v-if="!isEditorMode && !isLoading">
            <v-col class="plus-placeholder" cols="12" lg="4" md="4" sm="6" xl="3" xs="12">
                <div class="placeholder-block placeholder-primary" @click="isEditorMode=true">
                    <h2 class="mx-0 my-0 text-h2">
                        <v-icon class="mr-2" style="font-size: 64px">mdi-plus</v-icon>
                        New
                    </h2>
                </div>
            </v-col>
            <v-col v-for="(area,index) in areas" :key="area.uuid" cols="12" lg="4" md="4" sm="6" xl="3" xs="12">
                <image-aoi-card :area="area" @click.native="onGalleryShow(index)"></image-aoi-card>
            </v-col>


            <light-box v-if="mediaList.length" ref="lightbox" :disable-zoom="false" :media="mediaList"
                       :show-caption="true"
                       :show-footer-count="false" :show-light-box="false" :show-thumbs="true">
                <template slot="close" slot-scope="{ closeFn, mediaItem }">
                    <a :href="mediaList[mediaItem].download">
                        <v-icon
                                class="white--text vue-lb-header-button mr-4"
                                title="Download"
                                white
                        >mdi-download
                        </v-icon>
                    </a>
                    <v-icon
                            class="white--text vue-lb-header-button mr-2"
                            large
                            title="Close"
                            type="button"
                            @click.stop="closeFn"
                    >mdi-close
                    </v-icon>
                </template>
            </light-box>

        </v-row>
        <div v-if="!isEditorMode && isLoading" class="row">
            <div v-for="n in 12" :key="n" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="ph-item">
                    <div class="ph-col-12">
                        <div class="ph-picture mb-3" style="height:250px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AreaEditor from "@/components/Inputs/AreaEditor";
import ImageAoiCard from "@/views/dashboard/Sample/ImageAoiCard";
import LightBox from "@/components/LightBox/LightBox";
import { getUrlPath } from "@/util";

export default {
  name: "AreasOfInterestTab",
  components: {
    ImageAoiCard,
    AreaEditor,
    LightBox,
  },
  data() {
    return {
      isEditorMode: false,
      isLoading: false,
      rectArray: [],
      sampleName: "",
    };
  },
  computed: {
    mediaList() {
      return this.areas.map((element) => ({
        thumb: element.outputImageAoiThumb,
        src: element.outputImageAoi,
        download: `${process.env.VUE_APP_API_BASE_URL}/signed-url?token=${localStorage.getItem("feathers-jwt")}&url=${encodeURI(getUrlPath(element.outputImageAoi))}`,
        caption: element.name ? element.name : "Untitled",
      }));
    },
    ...mapGetters({ media: "getMedia", areas: "getImageAreas" }),
  },
  methods: {
    onClose() {
      if (this.rectArray.length) {
        this.$confirm("Cancel area analysis", "Discard changes and get back to sample page?")
            .yes(() => {
              this.isEditorMode = false;
            });
      } else {
        this.isEditorMode = false;
      }
    },
    onProcess() {
      this.isLoading = true;
      const inputAreas = this.rectArray.map(({ x, y, width, height }, index) => ({
        id: `uuid${ index }`,
        units: "pixels",
        left: Math.round(x),
        top: Math.round(y),
        width: Math.round(width),
        height: Math.round(height),
        text: `Area ${ index }`,
      }));
      this.createImageArea({
        mediaUuid: this.media.uuid,
        inputAreas,
        name: this.sampleName,
      })
          .then(() => {
            this.isEditorMode = false;
            this.rectArray.splice(0, this.rectArray.length);
            return this.fetchMediaAreas(this.media.uuid);
          }).then(() => {
        this.isLoading = false;
      })
          .catch(() => {
            this.isLoading = false;
          });
    },
    onGalleryShow(index) {
      this.$refs.lightbox.showImage(index);
    },
    ...mapActions(["fetchMediaAreas", "createImageArea"]),
  },
};
</script>

<style lang="scss">
.plus-placeholder {
  position: relative;


  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .placeholder-block {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    min-height: auto;
    transition: all 0.2s ease-in-out;
    border: 2px dashed #22bfcd;
    color: #22bfcd;

    .v-icon {
      color: #22bfcd;
    }

    &:hover {
      border: 2px dashed #febe1e;
      color: #febe1e;

      .v-icon {
        color: #febe1e;
      }
    }
  }
}
</style>
