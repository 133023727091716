<template>
    <div class="samples-tab__content">
        <v-row v-if="isLoading">
            <v-col v-for="i in 8" :key="i" cols="12" lg="4" md="4" sm="6" xl="3" xs="12">
                <v-skeleton-loader elevation="4" min-height="300px" type="image">
                </v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else class="row">
            <v-col v-for="(sample, index) in samples" :key="sample.uuid" cols="12" lg="4" md="4" sm="6" xl="3" xs="12">
                <sample-card
                        :is-busy="renameIndex.includes(index) || (sample.sample && sample.sample.isProcessing) || (sample.abc && sample.abc.isProcessing)"
                        :is-renaming="renameIndex.includes(index)"
                        :sample="sample"
                        @delete="onDeleteSample(sample.uuid)"
                        @rename="onRenameSample(sample.uuid,sample.name,index)"
                ></sample-card>
            </v-col>
            <v-col v-if="total > samples.length" class="text-center w-100">
                <v-btn :loading="isLoadingMore" color="warning" dark rounded x-large @click="onLoadMore">Load More
                    ({{ total - samples.length }})
                </v-btn>
            </v-col>
            <v-col v-if="!isLoading && !samples.length" cols="12">
                <div class="samples-list-placeholder">
                    <h1 class="mb-3">This project has no samples in it!</h1>
                    <h2 class="mb-3">You can try to upload files form UPLOADS tab.</h2>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SampleCard from "@/views/dashboard/ProjectDetails/SampleCard";

export default {
  name: "SamplesTab",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    projectUuid: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: {
    SampleCard,
  },
  data: () => ({
    renameIndex: [],
    isLoadingMore: false,
  }),
  computed: {
    ...mapGetters({
      samples: "getMediaList",
      role: "authRole",
      userUuid: "authUserId",
      total: "getMediaTotal",
    }),
  },
  methods: {
    onLoadMore() {
      this.isLoadingMore = true;
      this.fetchMediaList({
        projectUuid: this.projectUuid,
        loadMore: true,
      }).then(() => {
        this.isLoadingMore = false;
      });
    },
    finishRename(index) {
      this.renameIndex = this.renameIndex.filter((element) => element != index);
    },
    onDeleteSample(uuid) {
      this.$confirm("Delete sample", "Are you sure you want to delete this isample ?")
          .yes(() => {
            this.$emit("busy");
            this.deleteMedia(uuid)
                .then(() => {
                  this.$success(`Sample was removed successfully`);
                  this.$emit("update");
                })
                .catch(() => {
                });
          });
    },
    onRenameSample(uuid, name, index) {
      this.$prompt("Rename sample", "Provide new valid sample name", name, "Enter new sample name")
          .apply((value) => {
            this.renameIndex.push(index);
            this.updateMedia({ uuid, name: value })
                .then((data) => {
                  this.updateMediaName({ mediaUuid: uuid, name: data.name });
                  this.finishRename(index);
                });
          });
    },
    onSampleNavigate() {
      this.$emit("busy");
    },
    ...mapActions([
      "updateMedia",
      "deleteMedia",
      "fetchMediaList",
    ]),
    ...mapMutations(["updateMediaName"]),
  },
  mounted() {
    this.$eventBus.$on("sample:navigate", this.onSampleNavigate);
  },
  beforeDestroy() {
    this.$eventBus.$off("sample:navigate", this.onSampleNavigate);
  },
};
</script>

<style lang="scss">
.samples-list-placeholder {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  border: 1px dashed #363636;
  justify-content: center;
  align-items: center;

  h1, h2 {
    color: #363636;
    opacity: 0.8;
  }
}
</style>
