<template>
    <div class="sample-details">
        <v-card>
            <v-toolbar color="#363636" dark flat>
                <v-toolbar-title>Image details</v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs
                            v-model="currentTab"
                            centered
                            hide-slider
                            slider-color="warning"
                    >
                        <v-tab id="bronze-tab" href="#ai-vision">
                            <v-icon class="mx-4">mdi-eye-check-outline</v-icon>
                            AI-Vision
                        </v-tab>
                        <v-tab id="silver-tab" href="#aoi">
                            <v-icon class="mx-4">mdi-group</v-icon>
                            Areas of interest
                        </v-tab>
                        <v-tab v-if="['PRINT_AD','INSTORE_SIGNAGE','OUTDOOR_BILLBOARD','ONLINE_IMAGE','OTHER'].includes(media.project.type)" id="gold-tab"
                               href="#abc">
                            <v-icon class="mx-4">mdi-bullseye-arrow</v-icon>
                            ABC Score
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-tabs-items v-model="currentTab" :key="refreshKey">
                <v-tab-item value="ai-vision">
                    <v-card class="px-4 py-4" flat>
                        <ai-vision-tab></ai-vision-tab>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="aoi">
                    <v-card class="px-4 py-6" flat>
                        <areas-of-interest-tab></areas-of-interest-tab>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="['PRINT_AD','INSTORE_SIGNAGE','OUTDOOR_BILLBOARD','ONLINE_IMAGE','OTHER'].includes(media.project.type)"
                            value="abc">
                    <v-card class="px-4 py-4" flat>
                        <abc-score-tab></abc-score-tab>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import store from "@/store";
import AiVisionTab from "@/views/dashboard/Sample/AiVisionTab";
import AreasOfInterestTab from "@/views/dashboard/Sample/AreasOfInterestTab";
import AbcScoreTab from "@/views/dashboard/Sample/AbcScoreTab";
import { mapGetters } from "vuex";

export default {
  name: "SampleDetailsPage",
  components: {
    AiVisionTab,
    AreasOfInterestTab,
    AbcScoreTab,
  },
  data: () => ({
    currentTab: "ai-vision",
  }),
  computed: {
    ...mapGetters({ media: "getMedia", refreshKey: "getMediaRefreshKey" }),
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("fetchMedia", to.params.uuid)
         .then((media) => {
           to.meta.breadcrumbs[2].text = store.getters.getMediaProject.name;
           to.meta.breadcrumbs[2].href = `#/admin/projects/${ media.project.uuid }`;
           to.meta.breadcrumbs[3].text = media.name;
           next();
         })
         .catch((error) => {
           console.log(error);
           if (error.code === 404) {
             next({ name: "NotFound" });
           }
         });
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch("fetchMedia", to.params.uuid)
         .then((media) => {
           to.meta.breadcrumbs[2].text = store.getters.getProject.name;
           to.meta.breadcrumbs[2].href = `#/admin/projects/${ media.project.uuid }`;
           to.meta.breadcrumbs[3].text = media.name;
           next();
         })
         .catch(({ code }) => {
           if (code === 404) {
             next({ name: "NotFound" });
           }
         });
  }
};
</script>

<style lang="scss">
.sample-details {
  #bronze-tab {
    ///    background-color: #a15520;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    background: rgb(120, 52, 15);
    background: radial-gradient(circle, rgba(120, 52, 15, 1) 0%, rgba(150, 75, 26, 1) 89%, rgba(189, 115, 43, 1) 96%, rgba(216, 151, 56, 1) 100%);

    color: #ffffff;

    .v-icon {
      color: #ffffff;
    }

    //      opacity: 0.5;
    &.v-tab--active {
      .v-icon {
        color: #a15520;
      }

      opacity: 1;
      background: white;
      color: #a15520;
    }
  }

  #silver-tab {
    //background-color: #878f9a;
    background: rgb(126, 134, 145);
    background: radial-gradient(circle, rgba(126, 134, 145, 1) 0%, rgba(143, 152, 161, 1) 89%, rgba(162, 169, 179, 1) 96%, rgba(215, 218, 223, 1) 100%);

    margin-left: 15px;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #ffffff;

    .v-icon {
      color: #ffffff;
    }

    &.v-tab--active {
      .v-icon {
        color: #878f9a;
      }

      opacity: 1;
      background: white;
      color: #878f9a;
    }
  }

  #gold-tab {
    //background-color: #ac7b03;
    background: rgb(140, 97, 0);
    background: radial-gradient(circle, rgba(140, 97, 0, 1) 0%, rgba(157, 110, 2, 1) 89%, rgba(210, 159, 9, 1) 96%, rgba(246, 202, 19, 1) 100%);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #ffffff;

    .v-icon {
      color: #ffffff;
    }

    &.v-tab--active {
      opacity: 1;
      background: white;
      color: #ac7b03;

      .v-icon {
        color: #ac7b03;
      }
    }
  }
}
</style>
