import { apiClient, socketClient } from "@/api";
import router from "@/router";

export default {
  state: {
    user: {
      email: "",
      firstName: "",
      lastName: "",
      uuid: "",
      role: "USER",
      companyUuid: null,
      company: null,
    },
  },
  getters: {
    authUserInfo: ({ user }) => user,
    authRole: ({ user }) => user.role,
    authUserId: ({ user }) => user.uuid,
    authCompany: ({ user }) => user.company,
  },

  mutations: {
    setUserAuthInfo({ user }, { uuid, email, firstName, lastName, role, companyUuid, company }) {
      user.role = role;
      user.uuid = uuid;
      user.email = email;
      user.firstName = firstName;
      user.lastName = lastName;
      user.companyUuid = companyUuid;
      user.company = company;
    },
    setUserBalance({ user }, balance) {
      if (user && user.company) {
        user.company.credits = balance;
      }
    },
  },

  actions: {
    login(context, payload) {
      return Promise.all([
        apiClient.authenticate(payload),
        socketClient.authenticate(payload),
      ]);
    },

    logout() {
      Promise.all([socketClient.logout(), apiClient.logout()]).then(() => {
        router.push({ path: "/login" });
      });
    },

    updateProfile({ commit, state }, { firstName, lastName }) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("profile")
          .patch(state.user.uuid, { firstName, lastName })
          .then((updatedUser) => {
            commit("setUserAuthInfo", updatedUser);
            resolve();
          })
          .catch(reject);
      });
    },

    updatePassword({ state }, { password }) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("profile")
          .patch(state.user.uuid, { password })
          .then(resolve)
          .catch(reject);
      });

    },
  },
};
