import store from "../store";
import { apiClient } from "@/api";

export default function guest({ next }) {
  apiClient.authenticate()
           .then(({ user }) => {
             store.commit("setUserAuthInfo", user);
             return next({ path: "/" });
           })
           .catch(() => {
             return next();
           });
}

