<template>
    <v-dialog :value="modalShow" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">Add credits for {{ companyName }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea v-model="model.description" :error-messages="errors.description"
                                        hint="Provide short description for this transaction"
                                        label="Description*"
                                        placeholder="Description"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    v-model="model.amount"
                                    :error-messages="errors.amount"
                                    label="Credits amount*"
                                    placeholder="Credits"
                                    type="number"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="modalShow=false">
                    Close
                </v-btn>
                <v-btn color="primary" text @click="onSubmit">
                    Add credits
                </v-btn>
            </v-card-actions>
            <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
                <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import { mapActions } from "vuex";

export default {
  name: "TransactionModal",
  mixins: [formMixin],
  data: () => ({
    modalShow: false,
    isBusy: false,
    companyName: "",
    model: {
      type: "INCOME",
      description: "",
      companyUuid: null,
      amount: 0,
    },
  }),
  methods: {
    onSubmit() {
      this.isBusy = true;
      this.model.amount = parseInt(this.model.amount);
      this.createTransaction(this.model)
          .then(() => {
            this.$success("Transaction was enqueued");
            this.modalShow = false;
            this.$emit("update");
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    onCreateTransaction({ uuid, name }) {
      this.resetForm();
      this.companyName = name;
      this.model.type = "INCOME";
      this.model.description = `Adding credits to company ${ name }(${ uuid }) from admin panel`;
      this.model.companyUuid = uuid;
      this.model.amount = 0;
      this.modalShow = true;
    },
    ...mapActions(["createTransaction"]),
  },
  created() {
    this.$eventBus.$on("transaction:create", this.onCreateTransaction);
  },
  beforeDestroy() {
    this.$eventBus.$off("transaction:create", this.onCreateTransaction);
  },
};
</script>

<style lang="scss">

</style>
