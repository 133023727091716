import { apiClient } from "@/api";

export default {
  state: {
    transactionsList: [],
    total: 0,
    limit: 10,
    page: 1,
    sort: "createdAt",
    sortDirection: -1,
    filter: {
      type: [],
      companyUuid: [],
    },
  },
  getters: {
    getTransactionsList: ({ transactionsList }) => transactionsList,
    getTransactionsTotal: ({ total }) => total,
    getTransactionsPage: ({ page }) => page,
    getTransactionsLimit: ({ limit }) => limit,
    getTransactionsSort: ({ sort }) => sort,
    getTransactionsSortDirection: ({ sortDirection }) => sortDirection,
    getTransactionsFilter: ({ filter }) => filter,
  },
  mutations: {
    setTransactionsSort(state, { sort, sortDirection }) {
      state.sort = sort;
      state.sortDirection = sortDirection;
    },
    setTransactions(state, newTransactionsList) {
      state.transactionsList.splice(0, state.transactionsList.length);
      state.transactionsList = [
        ...state.transactionsList,
        ...newTransactionsList,
      ];
    },
    setTransactionsFilter({ filter }, { type, companyUuid }) {
      filter.companyUuid.splice(0, filter.companyUuid.length);
      filter.type.splice(0, filter.type.length);
      if (type && type.length) {
        filter.type = [
          ...type,
        ];
      }
      if (companyUuid && companyUuid.length) {
        filter.companyUuid = [
          ...companyUuid,
        ];
      }
    },
    setTransactionsPage(state, pageNumber) {
      state.page = pageNumber;
    },
    setTransactionsLimit(state, newLimit) {
      state.limit = newLimit;
    },
  },
  actions: {
    fetchTransactionsList({ state, commit }) {
      return new Promise((resolve, reject) => {
        const query = {
          $limit: state.limit,
          $skip: (state.page - 1) * state.limit,
          $sort: {
            [state.sort]: state.sortDirection,
          },
        };
        if (state.filter.type.length) {
          query.type = {
            $in: state.filter.type,
          };
        }
        if (state.filter.companyUuid.length) {
          query.companyUuid = {
            $in: state.filter.companyUuid,
          };
        }
        apiClient
          .service("transactions")
          .find({ query })
          .then(({ total, data }) => {
            state.total = total;
            commit("setTransactions", data);
            return resolve(state.transactionsList);
          })
          .catch(reject);
      });
    },
    createTransaction(store, newTransactionData) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("transactions")
          .create(newTransactionData)
          .then(resolve)
          .catch(reject);
      });
    },
  },
};
