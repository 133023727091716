import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import EventBus from "@/plugins/event-bus";
import notify from "@/plugins/notify";
import dialogs from "@/plugins/dialogs";
import VueLazyLoad from "vue-lazyload";
import logger from "@/plugins/logger";


import "./assets/scss/main.scss";

Vue.config.productionTip = false;


Vue.use(notify);
Vue.use(dialogs);
Vue.use(VueLazyLoad);
Vue.use(EventBus);
Vue.use(logger);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
