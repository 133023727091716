<template>
    <div>
        <users-table :isLoading="isLoading" @update="syncUsersList"></users-table>
        <user-modal @update="syncUsersList"></user-modal>
        <password-modal></password-modal>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import UserModal from "@/views/dashboard/Users/UserModal";
import PasswordModal from "@/views/dashboard/Users/PasswordModal";
import UsersTable from "@/views/dashboard/Users/UsersTable";

export default {
  name: "UsersPage",
  components: {
    UserModal,
    PasswordModal,
    UsersTable,
  },
  data: () => ({
    isLoading: true,
  }),
  methods: {
    syncUsersList() {
      this.isLoading = true;
      this.fetchUserList()
          .catch(() => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    ...mapActions(["fetchUserList"]),
  },
  mounted() {
    this.syncUsersList();
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("fetchCompaniesList")
         .then(() => {
           next();
         })
         .catch(next((vm) => {
           vm.$handleError;
         }));
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch("fetchCompaniesList")
         .then(() => {
           next();
         })
         .catch(() => {
         });
  },
};
</script>
