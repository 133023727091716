<template>
    <div>
        <users-table-company :isLoading="isLoading" @update="syncUsersList"></users-table-company>
        <user-modal-company @update="syncUsersList"></user-modal-company>
        <password-modal></password-modal>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import UserModalCompany from "@/views/dashboard/Users/UserModalCompany";
import PasswordModal from "@/views/dashboard/Users/PasswordModal";
import UsersTableCompany from "@/views/dashboard/Users/UsersTableCompany";

export default {
  name: "UsersPageCompany",
  components: {
    UserModalCompany,
    PasswordModal,
    UsersTableCompany,
  },
  data: () => ({
    isLoading: true,
  }),
  methods: {
    syncUsersList() {
      this.isLoading = true;
      this.fetchUserList()
          .then(() => {
            this.isLoading = false;
          });
    },
    ...mapActions(["fetchUserList"]),
  },
  mounted() {
    this.syncUsersList();
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("fetchCompaniesList")
         .then(() => {
           next();
         });
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch("fetchCompaniesList")
         .then(() => {
           next();
         });
  },
};
</script>
