<template>
    <v-dialog :value="modalShow" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ companyModalTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="model.name" :error-messages="errors.name" label="Company name*"
                                          placeholder="Name"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    v-show="isCreating"
                                    v-model="model.credits"
                                    :error-messages="errors.credits"
                                    label="Initial credits"
                                    placeholder="Credits"
                                    type="number"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="closeCompanyModal">
                    Close
                </v-btn>
                <v-btn color="primary" text @click="onCreateOrUpdateSubmit">
                    {{ companyModalSubmitButton }}
                </v-btn>
            </v-card-actions>
            <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
                <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import { mapActions } from "vuex";
import { filterObjectForbidden } from "@/util";

export default {
  name: "CompanyModal",
  mixins: [formMixin],
  data: () => ({
    isCreating: false,
    isEditing: false,
    isBusy: false,
    model: {
      uuid: null,
      credits: 0,
      name: "",
    },
  }),
  computed: {
    companyModalSubmitButton() {
      return this.isCreating ? "Create company" : "Update company";
    },
    companyModalTitle() {
      return this.isCreating ? "Create company" : "Update company";
    },
    modalShow() {
      return this.isCreating || this.isEditing;
    },
  },
  methods: {
    closeCompanyModal() {
      this.isEditing = false;
      this.isCreating = false;
    },
    onCreateCompany() {
      this.resetForm();
      this.model.uuid = null;
      this.model.name = "";
      this.model.credits = 0;
      this.isCreating = true;
      this.isEditing = false;
    },
    onEditCompany({ uuid, name }) {
      this.resetForm();
      this.model.uuid = uuid;
      this.model.name = name;
      this.isCreating = false;
      this.isEditing = true;
    },
    onCreateOrUpdateSubmit() {
      if (this.isEditing) {
        this.onEditCompanySubmit();
      } else {
        this.onCreateCompanySubmit();
      }
    },
    onCreateCompanySubmit() {
      this.isBusy = true;
      this.createCompany(filterObjectForbidden(this.model, ["uuid"]))
          .then((company) => {
            this.$success(`Company ${ company.name } was created successfully`);
            this.isCreating = false;
            this.$emit("update");
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    onEditCompanySubmit() {
      this.isBusy = true;
      this.updateCompany(filterObjectForbidden(this.model, ["credits"]))
          .then((company) => {
            this.$success(`Company ${ company.name } was updated successfully`);
            this.isEditing = false;
            this.$emit("update");
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    ...mapActions(["createCompany", "updateCompany"]),
  },
  created() {
    this.$eventBus.$on("company:create", this.onCreateCompany);
    this.$eventBus.$on("company:edit", this.onEditCompany);
  },
  beforeDestroy() {
    this.$eventBus.$off("company:create", this.onCreateCompany);
    this.$eventBus.$off("company:edit", this.onEditCompany);
  },
};
</script>

<style lang="scss">

</style>
