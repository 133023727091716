<template>
    <v-sheet elevation="4">
        <v-data-table
                :footer-props="{'items-per-page-options': [10,25,50], 'show-current-page': true, 'show-first-last-page': true}"
                :headers="headers"
                :item-class="tableRowClassName"
                :items="transactions"
                :loading="isLoading"
                :options.sync="options"
                :server-items-length="total"
                item-key="uuid"
                sort-by="createdAt"
        >
            <template v-slot:top>
                <v-toolbar flat height="auto">
                    <v-row align="center" justify="center" no-gutters>
                        <v-col class="d-flex" cols="12" style="height: 64px;">
                            <v-toolbar-title class="mr-4 d-flex justify-center align-center">Transactions
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" xl="3">
                            <v-text-field append-icon="mdi-magnify" class="mr-4" dense filled hide-details
                                          label="Search">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" xl="3">
                            <v-select v-model="filter.type" :items="typeOptions" class="mr-4"
                                      clearable
                                      dense
                                      filled hide-details
                                      label="Filter by type"
                                      multiple
                                      @change="syncTransactionsList"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" xl="4">
                            <v-select v-model="filter.companyUuid" :items="companies" clearable dense
                                      filled
                                      hide-details label="Filter by company"
                                      multiple
                                      small-chips
                                      @change="syncTransactionsList"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="2" md="4" xl="2">
                            <v-btn v-if="filter.type.length || filter.companyUuid.length" class="ml-2" color="default"
                                   text @click="onFilterReset">
                                <v-icon>mdi-filter-remove-outline</v-icon>
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.createdAt="{ value }">
                {{ dateFormat(value) }}
            </template>
            <template v-slot:item.companyUuid="{ item }">
                {{ companyName(item) }}
            </template>
            <template v-slot:item.userUuid="{ item }">
                {{ userName(item) }}
            </template>
            <template v-slot:item.amount="{ value }">
                {{ decimalFormat(value) }}
            </template>
            <template v-slot:item.balance="{ value }">
                {{ decimalFormat(value) }}
            </template>

        </v-data-table>
    </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dateFormat, decimalFormat } from "@/util";

export default {
  name: "CreditsPage",
  data: () => ({
    dateFormat,
    decimalFormat,
    isLoading: true,
    headers: [
      { text: "Created", align: "center", value: "createdAt", sortable: true, filterable: true },
      { text: "Description", align: "start", value: "description", sortable: false, filterable: true },
      { text: "Company", align: "start", value: "companyUuid", sortable: true, filterable: true },
      { text: "User", align: "start", value: "userUuid", sortable: true, filterable: true },
      { text: "Amount", align: "end", value: "amount" },
      { text: "Balance", align: "end", value: "balance" },
      { text: "Type", align: "start", value: "type", sortable: true, filterable: true },
    ],
    options: null,
    typeOptions: [
      {
        value: "INCOME",
        text: "INCOME",
      },
      {
        value: "EXPENSE",
        text: "EXPENSE",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      limit: "getTransactionsLimit",
      total: "getTransactionsTotal",
      page: "getTransactionsPage",
      filter: "getTransactionsFilter",
      sort: "getTransactionsSort",
      sortDirection: "getTransactionsSortDirection",
      transactions: "getTransactionsList",
      companies: "getCompaniesOptions",
      role: "authRole",
    }),
  },
  watch: {
    options: {
      handler({ itemsPerPage, sortBy, sortDesc, page }, oldValue) {
        if (oldValue) {
          this.setTransactionsPage(page);
          if (sortBy.length) {
            this.setTransactionsSort({ sort: sortBy[0], sortDirection: sortDesc[0] ? -1 : 1 });
          }
          this.setTransactionsLimit(itemsPerPage);
          this.syncTransactionsList();
        }
      },
      deep: true,
    },
  },
  methods: {
    syncTransactionsList() {
      this.isLoading = true;
      this.fetchTransactionsList()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
          });
    },
    tableRowClassName(item) {
      return item.type === "EXPENSE" ? "error--text" : "primary--text";
    },
    onFilterReset() {
      this.setTransactionsFilter({ type: [], companyUuid: [] });
      this.setTransactionsPage(1);
      this.options.page = 1;
      this.syncTransactionsList();
    },
    companyName({ company }) {
      return company ? company.name : "";
    },
    userName({ user }) {
      return user ? `${ user.firstName } ${ user.lastName }` : "";
    },
    ...mapActions(["fetchTransactionsList", "fetchCompaniesList"]),
    ...mapMutations(["setTransactionsPage", "setTransactionsFilter", "setTransactionsSort", "setTransactionsLimit"]),
  },
  created() {
    if (this.role !== "ADMIN") {
      this.headers.splice(2, 1);
    }
  },
  mounted() {
    this.fetchCompaniesList()
        .then(this.syncTransactionsList)
        .catch(() => {
        });
  },
};
</script>

<style scoped>

</style>
