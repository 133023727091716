<template>
    <div class="project-details">

        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Project details</v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs
                            v-model="currentTab"
                            centered
                            slider-color="warning"
                    >
                        <v-tab href="#samples">
                            <v-icon class="mx-4">mdi-view-grid-outline</v-icon>
                            Samples
                        </v-tab>
                        <v-tab v-if="role === 'ADMIN' || role==='COMPANY' || userUuid===project.ownerUuid"
                               href="#properties">
                            <v-icon class="mx-4">mdi-square-edit-outline</v-icon>
                            Properties
                        </v-tab>
                        <v-tab v-if="role === 'ADMIN' || role==='COMPANY' || userUuid===project.ownerUuid"
                               href="#uploads">
                            <v-icon class="mx-4">mdi-cloud-upload-outline</v-icon>
                            Uploads
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-tabs-items v-model="currentTab">
                <v-tab-item value="samples">
                    <v-card class="px-4 py-4" flat>
                        <samples-tab :is-loading="isLoading" :project-uuid="project.uuid" @busy="isLoading=true"
                                     @update="syncProjectSamples"></samples-tab>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="role === 'ADMIN' || role==='COMPANY' || userUuid===project.ownerUuid"
                            value="properties">
                    <v-card flat>
                        <properties-tab></properties-tab>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="role === 'ADMIN' || role==='COMPANY' || userUuid===project.ownerUuid" value="uploads">
                    <v-card flat>
                        <uploads-tab @update="syncProjectSamples(true)"></uploads-tab>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import store from "@/store";
import SamplesTab from "@/views/dashboard/ProjectDetails/SamplesTab";
import PropertiesTab from "@/views/dashboard/ProjectDetails/PropertiesTab";
import UploadsTab from "@/views/dashboard/ProjectDetails/UploadsTab";

export default {
  name: "ProjectDetailsPage",
  components: {
    SamplesTab,
    PropertiesTab,
    UploadsTab,
  },
  data: () => ({
    isLoading: true,
    currentTab: "samples",
  }),
  computed: {
    ...mapGetters({
      project: "getProject",
      role: "authRole",
      userUuid: "authUserId",
    }),
  },
  methods: {
    syncProjectSamples(switchTab = false) {
      if (switchTab) {
        this.currentTab = "samples";
      }
      this.isLoading = true;
      this.invalidateMediaList();
      this.fetchMediaList({ projectUuid: this.project.uuid, loadMore: false })
          .then(() => {
            this.isLoading = false;
          })
          .catch();
    },
    ...mapActions(["fetchMediaList"]),
    ...mapMutations(["invalidateMediaList"]),
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("fetchProject", to.params.uuid)
         .then(() => {
           to.meta.breadcrumbs[2].text = store.getters.getProject.name;
           next(vm => vm.syncProjectSamples());
         })
         .catch(({ code }) => {
           if (code === 404) {
             return next({ name: "NotFound" });
           }
           next(false);
         });
  },
  beforeRouteUpdate(to, from, next) {
    this.isLoading = true;
    store.dispatch("fetchProject", to.params.uuid)
         .then(() => {
           to.meta.breadcrumbs[2].text = store.getters.getProject.name;
           this.syncProjectSamples();
           this.isLoading = false;
           next();
         })
         .catch(({ code }) => {
           if (code === 404) {
             return next({ name: "NotFound" });
           }
           next(false);
         });
  },
};
</script>

<style lang="scss">
</style>
