<template>
    <v-card :loading="isBusy" class="px-4 py-4">
        <v-card-title>Edit Password</v-card-title>
        <v-card-text>
            <password-input v-model="model.password" :error-messages="errors.password" label="New Password"
                            @input="resetField('password')"></password-input>
            <password-input v-model="model.password_confirmation" :error-messages="errors.password_confirmation"
                            label="Confirm password" @input="resetField('password_confirmation')"></password-input>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="isBusy" color="primary" @click="onSubmit()">
                <v-icon class="mr-2">mdi-lock-check-outline</v-icon>
                Update password
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import { mapActions } from "vuex";
import PasswordInput from "@/components/Inputs/PasswordInput";

export default {
  components: { PasswordInput },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [formMixin],
  data() {
    return {
      isBusy: false,
      model: {
        password: "",
        password_confirmation: "",
      },
      rules: {
        password: { isRequired: "Provide valid password" },
        password_confirmation: { validate: this.validatePasswordConfirm },
      },
    };
  },
  methods: {
    validatePasswordConfirm(value) {
      return this.model.password.length && value !== this.model.password ? "Passwords do not match" : true;
    },
    onSubmit() {
      this.resetForm();
      if (this.validate()) {
        this.isBusy = true;
        this.updatePassword({ password: this.password })
            .then(() => {
              this.$success("Password was changed successfully");
              this.password = "";
              this.password_confirmation = "";
              this.isBusy = false;
            });
      }
    },
    ...mapActions(["updatePassword"]),
  },
};
</script>
