<template>
    <v-dialog :value="visibility" max-width="400" persistent>
        <v-card>
            <v-card-title class="text-h5">{{ title }}</v-card-title>
            <v-card-text>{{ description }}</v-card-text>
            <v-card-text>
                <v-text-field :error-messages="error" :label="label" :value="value" large outlined
                              @input="onInput"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="onPromptApply">
                    <v-icon>mdi-check-all</v-icon>
                    {{ applyButton }}
                </v-btn>
                <v-btn color="default" dark large @click="onPromptCancel">
                    <v-icon>mdi-close</v-icon>
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    applyButton: {
      type: String,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  name: "PromptDialog",
  data: () => ({
    error: "",
  }),
  methods: {
    onInput(value) {
      this.error = "";
      this.$emit("input", value);
    },
    onPromptApply() {
      if (!this.value) {
        this.error = "Provide valid value";
        return;
      }
      this.$emit("apply");
    },
    onPromptCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>

</style>
