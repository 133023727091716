import { apiClient } from "@/api";
import { safeCopy, prepareMultipartFormData } from "@/util";

export default {
  state: {
    project: {
      uuid: "",
      name: "",
      description: "",
      keywords: "",
      thumb: "",
      ownerUuid: "",
      type: "",
      isArchive: false,
      owner: {},
    },
    projectList: [],
    total: 0,
    skip: 0,
    limit: 12,
  },
  getters: {
    getProject: ({ project }) => project,
    getProjectList: ({ projectList }) => projectList,
    getProjectTotal: ({ total }) => total,
  },
  mutations: {
    setProject({ project }, newProjectInstance) {
      safeCopy(newProjectInstance, project);
    },
    setProjectOwner({ project }, newProjectOwner) {
      safeCopy(newProjectOwner, project.owner);
    },
    appendProjects(state, appendProjectList) {
      state.projectList = [
        ...state.projectList,
        ...appendProjectList,
      ];
    },
    invalidateProjectList(state) {
      state.projectList.splice(0, state.projectList.length);
      state.skip = 0;
      state.total = 0;
    },
  },
  actions: {
    createProject({ state, commit }, projectData) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("projects")
          .create(prepareMultipartFormData(projectData), {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((projectInstance) => {
            commit("setProject", projectInstance);
            commit("setProjectOwner", projectInstance.owner);
            return resolve(state.project);
          })
          .catch(reject);
      });
    },
    updateProject({ state, commit }, { uuid, projectData }) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("projects")
          .patch(uuid, prepareMultipartFormData(projectData), {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((projectInstance) => {
            commit("setProject", projectInstance);
            commit("setProjectOwner", projectInstance.owner);
            resolve(state.project);
          })
          .catch(reject);
      });
    },
    fetchProject({ state, commit }, uuid) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("projects")
          .get(uuid)
          .then((projectInstance) => {
            commit("setProject", projectInstance);
            commit("setProjectOwner", projectInstance.owner);
            resolve(state.project);
          })
          .catch(reject);
      });
    },
    fetchProjects({ state, commit, rootState }, {
      loadMore,
      filterByMe,
      filterSearch,
      filterCompanies,
      filterArchive,
    }) {
      return new Promise((resolve, reject) => {
        const request = {
          query: {
            $limit: state.limit,
            $skip: loadMore ? state.skip : 0,
          },
        };
        if (filterByMe) {
          request.query.ownerUuid = rootState.auth.user.uuid;
        }
        request.query.isArchive = filterArchive;
        if (filterSearch && filterSearch.length) {
          request.query.$or = [
            { name: { $iLike: `%${ filterSearch }%` } },
            { description: { $iLike: `%${ filterSearch }%` } },
            { keywords: { $iLike: `%${ filterSearch }%` } },
          ];
        }
        if (filterCompanies) {
          request.query["$owner.companyUuid$"] = filterCompanies;
        }

        apiClient
          .service("projects")
          .find(request)
          .then(({ total, data }) => {
            if (!loadMore) {
              state.projectList.splice(0, state.projectList.length);
              state.skip = 0;
            }
            state.total = total;
            state.skip += data.length;
            commit("appendProjects", data);
            return resolve(state.projectList);
          })
          .catch(reject);
      });
    },
    deleteProject(context, uuid) {
      return apiClient.service("projects").remove(uuid);
    },
  },
};
