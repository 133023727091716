export default {
  ADMIN: [
    {
      name: "Dashboard",
      icon: "mdi-view-dashboard",
      path: "/admin/overview",
    },
    {
      name: "Projects",
      icon: "mdi-briefcase-variant",
      path: "/admin/projects",
    },
    {
      name: "Credits",
      icon: "mdi-currency-usd",
      path: "/admin/credits",
    },
    {
      name: "Companies",
      icon: "mdi-domain",
      path: "/admin/companies",
    },
    {
      name: "Users",
      icon: "mdi-account-multiple",
      path: "/admin/users",
    },
    {
      name: "Settings",
      icon: "mdi-cog",
      path: "/admin/settings",
    },
  ],
  COMPANY: [
    {
      name: "Dashboard",
      icon: "mdi-view-dashboard",
      path: "/admin/overview",
    },
    {
      name: "Projects",
      icon: "mdi-briefcase-variant",
      path: "/admin/projects",
    },
    {
      name: "Credits",
      icon: "mdi-currency-usd",
      path: "/admin/credits",
    },
    {
      name: "Users",
      icon: "mdi-account-multiple",
      path: "/admin/users-company",
    },
    {
      name: "Profile",
      icon: "mdi-account",
      path: "/admin/profile",
    },
  ],
  USER: [
    {
      name: "Dashboard",
      icon: "mdi-view-dashboard",
      path: "/admin/overview",
    },
    {
      name: "Projects",
      icon: "mdi-briefcase-variant",
      path: "/admin/projects",
    },
    {
      name: "Profile",
      icon: "mdi-account",
      path: "/admin/profile",
    },
  ],
};

