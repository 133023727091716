<template>
    <v-row>
        <v-col cols="12" md="6">
            <edit-profile-card/>
        </v-col>
        <v-col cols="12" md="6">
            <edit-password-card/>
        </v-col>
    </v-row>
</template>
<script>
import EditProfileCard from "./EditProfileCard.vue";
import EditPasswordCard from "./EditPasswordCard.vue";

export default {
  components: {
    EditProfileCard,
    EditPasswordCard,
  },
};
</script>
<style>

</style>
