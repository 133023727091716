<template>
    <div class="card project-card" @click="onClick">
        <div class="card-body project-card__body">
            <v-img :src="project.thumb?project.thumb:'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'300\' height=\'300\' viewBox=\'0 0 300 300\'%3E%3Crect fill=\'%23fff\' width=\'300\' height=\'300\'/%3E%3Ctext fill=\'rgba(34,191,205,1)\' font-family=\'Roboto\' font-size=\'30\' dy=\'10.5\' font-weight=\'bold\' x=\'50%25\' y=\'50%25\' text-anchor=\'middle\'%3ENO PREVIEW%3C/text%3E%3C/svg%3E'"
                   contain
                   lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==">
            </v-img>
        </div>
        <h2 class="project-card__title">{{ project.name }}</h2>
        <div class="project-card__type">{{ typeString[project.type] }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
    },
  },
  data: () => ({
    typeString: {
      "PRINT_AD": "Print Ad",
      "INSTORE_SIGNAGE": "In-store signage",
      "OUTDOOR_BILLBOARD": "Outdoor-billboard",
      "ONLINE_IMAGE": "Digital Ad Image",
      "WEBSITE": "Website",
      "PACKAGING": "Packaging",
      "SHELF_SET": "Shelf set",
      "VIDEO": "Video",
      "OTHER": "Other",
    },
  }),
  computed: {
    ...mapGetters({
      userId: "authUserId",
    }),
  },
  methods: {
    onClick() {
      this.$eventBus.$emit("project:navigate");
      this.$router.push({ name: "ProjectDetails", params: { uuid: this.project.uuid } });
    },
    ...mapActions(["deleteProject"]),
  },
};
</script>

<style lang="scss">
$project-card-color: darken(#22bfcd, 10%);

.project-card {
  box-shadow: 0 6px 10px -4px rgb(0, 0, 0) !important;
  position: relative;
  cursor: pointer;
  background: white;

  &__body {
    &-image {
      width: 100%;
      height: auto;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 30%;
      background-color: rgba(255, 255, 255, 0.95);
      border-bottom: 6px solid $project-card-color;
      transition: height 0.2s ease-in-out;
    }
  }

  &__title {
    color: $project-card-color;
    position: absolute;
    font-weight: 600;
    font-size: 1.2em;
    padding: 1em;
    margin: 0;
    text-align: left;
    display: block;
    width: 100%;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    left: 0;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  &__type {
    position: absolute;
    top: 2em;
    right: -0.5em;
    background-color: $project-card-color;
    padding: 1em;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__owner {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #5a283d;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
  }

  &__details {
    position: absolute;
    width: 100%;
    top: calc(50% - 21px);
    display: block;
    text-align: center;
  }
}

//
//@include media-breakpoint-up(sm) {
//  .project-card__title {
//    bottom: 10px;
//    font-size: 1em;
//
//  }
//  .project-card__type {
//    padding: 1em;
//    font-weight: 400;
//    font-size: 1em;
//  }
//}
//@include media-breakpoint-up(md) {
//  .project-card__title {
//    bottom: 10px;
//    font-size: 0.8em;
//  }
//  .project-card__type {
//    padding: 0.8em;
//    font-weight: 500;
//    font-size: 0.8em;
//  }
//}
//@include media-breakpoint-up(lg) {
//  .project-card__title {
//    font-size: 1em;
//  }
//  .project-card__type {
//    padding: 1em;
//    font-weight: 600;
//    font-size: 1em;
//  }
//}
//@include media-breakpoint-up(xl) {
//  .project-card__type {
//    padding: 1em;
//    font-weight: 600;
//    font-size: 1em;
//  }
//}


</style>
