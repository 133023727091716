import store from "@/store";

export default {
  install(Vue) {
    Vue.prototype.$notify = (message, type, timeout = 5000, dismissible = true) =>
      store.commit("addAlert", { message, type, timeout, dismissible });
    Vue.prototype.$error = (message, timeout = 5000, dismissible = true) => {
      store.commit("addAlert", { message, type: "error", timeout, dismissible });
    };
    Vue.prototype.$success = (message, timeout = 5000, dismissible = true) => {
      store.commit("addAlert", { message, type: "success", timeout, dismissible });
    };
    Vue.prototype.$warning = (message, timeout = 5000, dismissible = true) => {
      store.commit("addAlert", { message, type: "warning", timeout, dismissible });
    };
  },
};
