<template>
    <div>
        <v-row class="row">
            <v-col cols="12" md="3">
                <v-text-field v-model="filterSearch"
                              :disabled="isLoading||isLoadingMore"
                              append-icon="mdi-magnify"
                              clearable filled hide-details
                              label="Search project"
                              @input="onSearchInput"
                              @keypress.enter="onSearchClicked"
                              @click:clear="onSearchClear" @click:append="onSearchClicked">
                </v-text-field>
            </v-col>
            <v-col v-if="role === 'ADMIN'" class="d-flex flex-row" cols="12" md="4">
                <v-select v-model="filterCompanies" :disabled="isLoading||isLoadingMore" :items="companies" filled
                          hide-details label="Filter by company"
                          multiple @change="reloadProjectsList"></v-select>
                <v-checkbox v-model="filterArchive" :disabled="isLoading||isLoadingMore" class="ml-2" hide-details
                            label="Archived"
                            @change="reloadProjectsList"></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
                <v-checkbox v-model="filterByMe" :disabled="isLoading||isLoadingMore" hide-details label="Created by me"
                            @change="reloadProjectsList"></v-checkbox>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn v-show="filterByMe || isSearchFilterApplied || filterCompanies.length || filterArchive"
                       class="mt-3"
                       @click="onClearFilter">
                    <v-icon>mdi-filter-remove-outline</v-icon>
                    Clear
                </v-btn>
            </v-col>
            <v-col cols="12">
                <project-list-skeleton v-if="isLoading" class="mt-3"></project-list-skeleton>
                <project-list v-else :is-loading-more="isLoadingMore" @loadmore="onLoadMore"
                              @update="reloadProjectsList"></project-list>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProjectList from "@/views/dashboard/Projects/ProjectList";
import ProjectListSkeleton from "@/views/dashboard/Projects/ProjectListSkeleton";

export default {
  name: "ProjectListPage",
  components: { ProjectList, ProjectListSkeleton },
  data: () => ({
    isLoading: true,
    isLoadingMore: false,
    isSearchFilterApplied: false,
    filterSearch: "",
    filterByMe: true,
    filterArchive: false,
    filterCompanies: [],
  }),
  computed: {
    ...mapGetters({ role: "authRole", companies: "getCompaniesOptions" }),
  },
  methods: {
    onSearchClicked() {
      this.isSearchFilterApplied = (this.filterSearch.length > 0);
      this.reloadProjectsList();
    },
    onSearchClear() {
      if (this.isSearchFilterApplied) {
        this.isSearchFilterApplied = false;
        this.reloadProjectsList();
      }
    },
    onSearchInput(value) {
      if (value === "" && this.isSearchFilterApplied) {
        this.isSearchFilterApplied = false;
        this.reloadProjectsList();
      }
    },
    onClearFilter() {
      this.filterSearch = "";
      this.filterByMe = false;
      this.filterCompanies.splice(0, this.filterCompanies.length);
      this.filterArchive = false;
      this.reloadProjectsList();
    },
    onLoadMore() {
      this.isLoadingMore = true;
      this.fetchProjects({
        loadMore: true,
        filterCompanies: this.filterCompanies,
        filterSearch: this.filterSearch,
        filterByMe: this.filterByMe,
        filterArchive: this.filterArchive,
      }).then(() => {
        this.isLoadingMore = false;
      });
    },
    onProjectNavigate() {
      this.isLoading = true;
    },
    reloadProjectsList() {
      this.isLoading = true;
      this.fetchProjects({
        loadMore: false,
        filterCompanies: this.filterCompanies,
        filterSearch: this.filterSearch,
        filterByMe: this.filterByMe,
        filterArchive: this.filterArchive,
      })
          .then(() => {
            this.isLoading = false;
          })
          .catch(this.handleServerErrors);
    },
    ...mapActions(["fetchProjects", "deleteProject", "fetchCompaniesList"]),
  },
  beforeMount() {
    if (this.role === "ADMIN") {
      this.filterByMe = false;
      this.fetchCompaniesList();
    }
    this.reloadProjectsList();
  },
  mounted() {
    this.$eventBus.$on("project:navigate", this.onProjectNavigate);
  },
  beforeDestroy() {
    this.$eventBus.$off("project:navigate", this.onProjectNavigate);
  },
};
</script>

<style lang="scss">

</style>
