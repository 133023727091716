var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"background":"#f4f3ef","align-items":"start"},attrs:{"fill-height":"","fluid":""}},[_c('v-navigation-drawer',{staticClass:"app-sidebar",style:({zIndex:_vm.isMobileMode?3000:6}),attrs:{"mini-variant":_vm.drawerMinify,"permanent":!_vm.isMobileMode,"temporary":_vm.isMobileMode,"app":"","dark":"","mobile-breakpoint":"sm"},model:{value:(_vm.sidebarVisibility),callback:function ($$v) {_vm.sidebarVisibility=$$v},expression:"sidebarVisibility"}},[_c('v-list',[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-icon',[_c('v-img',{staticClass:"rounded-br-0",attrs:{"src":require("@/assets/images/logo.svg")}},[_c('a',{staticClass:"d-block",staticStyle:{"width":"100%","height":"100%"},attrs:{"href":"https://www.synqrinus.com","target":"_blank"}})])],1),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Design Optics ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.navLinks[_vm.authRole]),function(ref,index){
var path = ref.path;
var icon = ref.icon;
var name = ref.name;
return _c('v-list-item',{key:index,staticClass:"pl-3",attrs:{"to":path,"color":"primary","link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(name))])],1)}),1)],1),_c('v-app-bar',{staticStyle:{"z-index":"100"},attrs:{"app":"","light":""}},[(!_vm.isMobileMode)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawerMinify = !_vm.drawerMinify}}}):_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.mobileMenu=!_vm.mobileMenu}}}),_c('v-breadcrumbs',{attrs:{"items":_vm.$route.meta.breadcrumbs,"large":""}}),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{name: 'CreateProject'},"color":"primary","depressed":"","large":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Project ")],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-title',[_vm._v("Log out")])],1),_c('v-list-item',{attrs:{"to":{name: 'ProfilePage'},"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("Profile")])],1)],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }