<template>
    <v-row>
        <v-col v-if="!projects.length" cols="12">
            <div class="list-placeholder">
                <h1 class="mb-3">No projects were found!</h1>
                <h2 class="mb-3">You can try to change filtering criteria.</h2>
                <div>or alternatively you can
                    <v-btn :to="{name:'CreateProject'}" color="primary" dark large rounded>
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Create Project
                    </v-btn>
                </div>
            </div>
        </v-col>
        <v-col v-for="project in projects" :key="project.uuid" lg="4" md="4" sm="6" xl="3" xs="12">
            <project-card :project="project" @update="$emit('update')"></project-card>
        </v-col>
        <v-col v-if="total > projects.length" class="text-center w-100">
            <v-btn :loading="isLoadingMore" color="primary" dark elevation="8" rounded x-large
                   @click="$emit('loadmore')">Load
                More ({{ total - projects.length }})
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProjectCard from "@/views/dashboard/Projects/ProjectCard";

export default {
  name: "ProjectList",
  props: {
    isLoadingMore: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: { ProjectCard },
  computed: {
    ...mapGetters({
      projects: "getProjectList",
      total: "getProjectTotal",
    }),
  },
  methods: {
    ...mapActions(["fetchProjects"]),
  },
};
</script>

<style lang="scss">
.list-placeholder {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  border: 1px dashed #363636;
  justify-content: center;
  align-items: center;

  h1, h2 {
    color: #363636;
    opacity: 0.8;
  }
}
</style>
