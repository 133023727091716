<template>
    <v-card :loading="isBusy" class="px-4 py-4">
        <v-card-title></v-card-title>
        <v-card-text>
            <v-text-field v-model="model.firstName" :error-messages="errors.firstName" label="First name"
                          @input="resetField('firstName')"></v-text-field>
            <v-text-field v-model="model.lastName" :error-messages="errors.lastName" label="Last name"
                          @input="resetField('lastName')"></v-text-field>

            <v-text-field :value="model.company.name" disabled label="Company"></v-text-field>
            <v-text-field :value="model.email" disabled label="Email"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="isBusy" color="primary" @click="onSubmit">
                <v-icon class="mr-2">mdi-account-check-outline</v-icon>
                Update Account Info
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [formMixin],
  props: {
    user: Object,
  },
  data: () => ({
    isBusy: false,
    rules: {
      firstName: { isRequired: "Provide valid first name" },
      lastName: { isRequired: "Provide valid last name" },
    },
  }),
  computed: {
    ...mapGetters({ model: "authUserInfo" }),
  },
  methods: {
    onSubmit() {
      if (this.validate()) {
        this.isBusy = true;
        this.updateProfile({ firstName: this.model.firstName, lastName: this.model.lastName })
            .then(() => {
              this.$success("Profile updated successfully.");
              this.isBusy = false;
            });
      }
    },
    ...mapActions(["updateProfile"]),
  },
};
</script>

