<template>
    <v-row no-gutters>
        <v-col class="px-4 py-4" cols="12">
            <file-input
                    v-if="['PRINT_AD','INSTORE_SIGNAGE','OUTDOOR_BILLBOARD','PACKAGING','SHELF_SET','OTHER', 'ONLINE_IMAGE'].includes(project.type)"
                    v-model="uploads"
                    :accept="['image/png','image/jpg','image/gif','image/jpeg']"></file-input>
        </v-col>
        <v-col class="px-4 py-4" cols="12" sm="9">
            <v-checkbox v-model="processSamples" class="mt-3" label="Process images upon uploading"></v-checkbox>
        </v-col>
        <v-col class="px-4 py-4" cols="12" sm="3">
            <v-btn v-show="uploads.length || websiteUrls.length"
                   color="primary" title="Update" @click="onUpload">
                <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
                Upload samples
            </v-btn>
        </v-col>
        <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "UploadsTab",
  components: {
    FileInput,
  },
  data: () => ({
    isBusy: false,
    uploads: [],
    websiteUrls: [],
    processSamples: true,
  }),
  computed: {
    ...mapGetters({
      project: "getProject",
    }),
  },
  methods: {
    onUpload() {
      this.isBusy = true;
      this.updateProject({
        uuid: this.project.uuid,
        projectData: {
          websiteUrls: this.websiteUrls,
          uploads: this.uploads,
          processSamples: this.processSamples,
        },
      })
          .then(() => {
            this.$success(`Additional samples were uploaded successfully`);
            this.$emit("update");
            this.isBusy = false;
            this.uploads.splice(0, this.uploads.length);
            this.websiteUrls.splice(0, this.websiteUrls.length);
          })
          .catch(() => {
            this.isBusy = false;
          });
    },
    ...mapActions(["updateProject"]),
  },
};
</script>

<style scoped>

</style>
