<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card :loading="isBusy" elevation="4">
                <v-img :alt="sample.name" :src="sample.thumb" class="warning--text align-end text-uppercase"
                       contain
                       gradient="to bottom, rgba(255,255,255,0), rgba(255,255,255,.9) 80%, rgba(255,255,255,.95)"
                       lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==">
                    <v-card-title v-show="!sample.isProcessing" class="text-ellipsis">{{ sample.name }}
                    </v-card-title>
                </v-img>
                <v-fade-transition>
                    <v-overlay
                            v-if="hover && !sample.isProcessing"
                            absolute
                            color="primary"
                    >
                        <div class="d-flex flex-column">
                            <v-btn class="mt-4 justify-start" large
                                   @click="onSampleNavigate">
                                <v-icon class="mr-2">mdi-image-search-outline</v-icon>
                                See details
                            </v-btn>
                            <v-btn :loading="isRenaming" class="justify-start my-4" color="primary"
                                   large
                                   @click="$emit('rename')">
                                <v-icon class="mr-2">mdi-image-edit-outline</v-icon>
                                Rename
                            </v-btn>
                            <v-btn :loading="isBusy" class="justify-start mt-8" color="error" large
                                   @click="$emit('delete')">
                                <v-icon class="mr-2">mdi-image-remove</v-icon>
                                Delete
                            </v-btn>
                        </div>
                    </v-overlay>
                </v-fade-transition>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
export default {
  name: "SampleCard",
  props: {
    sample: {
      type: Object,
      default() {
        return {};
      },
    },
    isBusy: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isRenaming: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    onSampleNavigate() {
      this.$eventBus.$emit("sample:navigate");
      this.$router.push({ name: "SampleDetails", params: { uuid: this.sample.uuid } });
    },
  },
};
</script>

<style scoped>

</style>
