import DashboardLayout from "@/views/dashboard/InternalLayout";
import ExternalLayout from "@/views/external/ExternalLayout";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

// GeneralViews
import Login from "@/views/external/Login";
import NotFound from "@/views/external/NotFoundPage";

// Admin Pages
import Profile from "@/views/dashboard/Profile/ProfilePage";
import ProjectCreatePage from "@/views/dashboard/ProjectCreate/ProjectCreatePage";
import ProjectListPage from "@/views/dashboard/Projects/ProjectListPage";
import ProjectDetailsPage from "@/views/dashboard/ProjectDetails/ProjectDetailsPage";
import SampleDetailsPage from "@/views/dashboard/Sample/SampleDetailsPage";
import CreditsPage from "@/views/dashboard/Credits/CreditsPage";
import UsersPage from "@/views/dashboard/Users/UsersPage";
import UsersPageCompany from "@/views/dashboard/Users/UsersPageCompany";
import CompaniesPage from "@/views/dashboard/Companies/CompaniesPage";
import SettingsPage from "@/views/dashboard/Settings/SettingsPage";
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ "@/views/dashboard/Overview.vue");

const routes = [
  {
    path: "/",
    component: ExternalLayout,
    redirect: "/admin/overview",
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { middleware: guest },
      },
    ],
  },
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/overview",
    meta: { middleware: auth },
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
        meta: { middleware: auth },
      },
      {
        path: "projects/create-project",
        name: "CreateProject",
        component: ProjectCreatePage,
        meta: {
          middleware: auth,
          title: "Create Project",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, {
            text: "Create Project",
            disabled: true,
          }],
        },
      },
      {
        path: "projects",
        name: "ListProjects",
        component: ProjectListPage,
        meta: {
          middleware: auth,
          title: "Projects",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Projects", disabled: true }],
        },
      },
      {
        path: "projects/:uuid",
        name: "ProjectDetails",
        component: ProjectDetailsPage,
        meta: {
          middleware: auth,
          title: "Project details",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, {
            text: "Projects",
            href: "#/admin/projects",
            disabled: false,
          }, { text: "Project Name", disabled: true }],
        },
      },
      {
        path: "projects/media/:uuid",
        name: "SampleDetails",
        component: SampleDetailsPage,
        meta: {
          middleware: auth,
          title: "Image details",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, {
            text: "Projects",
            href: "#/admin/projects",
            disabled: false,
          }, { text: "Project Name", href: "#/", disabled: false }, { text: "Sample Name", disabled: true }],
        },
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: Profile,
        meta: {
          middleware: auth,
          title: "Profile",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Profile", disabled: true }],
        },
      },
      {
        path: "credits",
        name: "CreditsPage",
        component: CreditsPage,
        meta: {
          middleware: auth,
          title: "Payment history",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, {
            text: "Credits history",
            disabled: true,
          }],
        },
      },
      {
        path: "users",
        name: "UsersPage",
        component: UsersPage,
        meta: {
          middleware: auth,
          title: "User accounts",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Users", disabled: true }],
        },
      },
      {
        path: "users-company",
        name: "UsersPageCompany",
        component: UsersPageCompany,
        meta: {
          middleware: auth,
          title: "User Accounts",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Users", disabled: true }],
        },
      },
      {
        path: "companies",
        name: "CompaniesPage",
        component: CompaniesPage,
        meta: {
          middleware: auth,
          title: "Companies",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Companies", disabled: true }],
        },
      },
      {
        path: "settings",
        name: "SettingsPage",
        component: SettingsPage,
        meta: {
          middleware: auth,
          title: "Settings page",
          breadcrumbs: [{ text: "Home", to: { name: "Overview" } }, { text: "Settings", disabled: true }],
        },
      },
    ],
  },
  { path: "*", name: "NotFound", component: NotFound },
];

export default routes;
