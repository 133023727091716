<template>
    <v-main>
        <v-container class="px-0 py-0" fill-height fluid>
            <v-slide-x-transition mode="out-in">
                <router-view/>
            </v-slide-x-transition>
            <v-footer absolute class="transparent grey--text">
                <v-spacer></v-spacer> &#169; {{ year }} <a class="ml-2" href="https://www.synqrinus.com" rel="noopener"
                                                           target="_blank"><img alt="Synqrinus"
                                                                                height="27"
                                                                                src="@/assets/images/logo-alt.svg"/></a>
            </v-footer>
        </v-container>
    </v-main>
</template>

<script>
export default {
  name: "ExternalLayout",
  data: () => ({
    year: new Date().getFullYear(),
  }),
};
</script>

<style scoped>


</style>
