import { apiClient } from "@/api";

export default {
  state: {
    companiesList: [],
    invalidated: true,
  },
  getters: {
    getCompaniesList: ({ companiesList }) => companiesList,
    getCompaniesOptions: ({ companiesList }) => companiesList
      .map((company) => ({ value: company.uuid, label: company.name, text: company.name }))
      .sort((company1, company2) => company2.label < company1.label),
  },
  mutations: {
    setCompanies(state, newCompanyList) {
      state.companiesList.splice(0, state.companiesList.length);
      state.companiesList = [
        ...state.companiesList,
        ...newCompanyList,
      ];
      state.invalidated = false;
    },
    invalidateCompaniesList(state) {
      state.companiesList.splice(0, state.companiesList.length);
      state.invalidated = true;
    },
  },
  actions: {
    fetchCompaniesList({ state, commit }) {
      if (!state.invalidated) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        apiClient
          .service("companies")
          .find()
          .then((data) => {
            commit("setCompanies", data);
            return resolve(state.companiesList);
          })
          .catch(reject);
      });
    },
    createCompany({ commit }, newCompanyData) {
      commit("invalidateCompaniesList");
      return new Promise((resolve, reject) => {
        apiClient
          .service("companies")
          .create(newCompanyData)
          .then(resolve)
          .catch(reject);
      });
    },
    updateCompany({ commit }, newCompanyData) {
      commit("invalidateCompaniesList");
      return new Promise((resolve, reject) => {
        apiClient
          .service("companies")
          .patch(newCompanyData.uuid, newCompanyData)
          .then(resolve)
          .catch(reject);
      });
    },
    deleteCompany({ commit }, uuid) {
      commit("invalidateCompaniesList");
      return apiClient
        .service("companies")
        .remove(uuid);
    },
  },
};
