<template>
    <v-app id="app">
        <v-slide-x-transition mode="out-in">
            <router-view/>
        </v-slide-x-transition>
        <notify-pool></notify-pool>
        <confirm-dialog v-bind="confirm" @no="onConfirmNo" @yes="onConfirmYes"></confirm-dialog>
        <prompt-dialog v-model="prompt.value" v-bind="prompt" @apply="onPromptApply"
                       @cancel="onPromptCancel"></prompt-dialog>
    </v-app>
</template>

<script>
import NotifyPool from "@/components/Notify/NotifyPool";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import PromptDialog from "@/components/Dialogs/PromptDialog";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  components: { NotifyPool, ConfirmDialog, PromptDialog },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      confirm: "getConfirm",
      prompt: "getPrompt",
    }),
  },
  methods: {
    onConfirmYes() {
      this.confirmHide();
      this.confirm.onYes();
    },
    onConfirmNo() {
      this.confirmHide();
      this.confirm.onNo();
    },
    onPromptApply() {
      this.promptHide();
      this.prompt.onInput(this.prompt.value);
    },
    onPromptCancel() {
      this.promptHide();
    },
    ...mapMutations({
      confirmHide: "confirmHide",
      promptHide: "promptHide",
    }),
  },
};
</script>
