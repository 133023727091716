const formMixin = {
  data: () => ({
    rules: {},
    errors: {},
  }),
  methods: {
    resetForm() {
      if (Array.isArray(this.model)) {
        for (let i = 0; i < this.model.length; i++) {
          for (const fieldKey in this.rules) {
            this.$set(this.errors[i], fieldKey, "");
          }
        }
      } else {
        for (const key in this.errors) {
          this.errors[key] = "";
        }
      }
    },
    resetArrayField(index, fieldKey) {
      this.errors[index][fieldKey] = "";
    },
    appendArrayField() {
      this.errors.push({});
      for (const fieldKey in this.rules) {
        this.$set(this.errors[this.errors.length - 1], fieldKey, "");
      }
    },
    removeArrayField(index) {
      this.errors.splice(index, 1);
    },
    resetField(fieldKey) {
      this.errors[fieldKey] = "";
    },
    validate(context = null, model = null) {
      const errorContext = context ? context : this.errors;
      const modelContext = model ? model : this.model;
      let validated = true;
      for (const fieldKey in this.rules) {
        const fieldRules = this.rules[fieldKey];
        if (fieldRules.isRequired && !modelContext[fieldKey]) {
          errorContext[fieldKey] = fieldRules.isRequired;
          validated = false;
        }
        if (fieldRules.validate && typeof fieldRules.validate === "function") {
          const validationResult = fieldRules.validate(modelContext[fieldKey]);
          if (validationResult !== true) {
            errorContext[fieldKey] = validationResult;
            validated = false;
          }
        }
      }
      return validated;
    },
    validateArray() {
      let validated = true;
      if (Array.isArray(this.model)) {
        for (let i = 0; i < this.model.length; i++) {
          if (!this.validate(this.errors[i], this.model[i])) {
            validated = false;
          }
        }
      }
      return validated;
    },
    handleServerErrors({ errors, code }) {
      if (errors && code === 400) {
        for (const errorKey in errors) {
          console.log("Setting fields error");
          this.$set(this.errors, errorKey, errors[errorKey]);
        }
      }
    },
  },
  created() {
    if (Array.isArray(this.model)) {
      this.errors = [];
      for (let i = 0; i < this.model.length; i++) {
        this.errors.push({});
        for (const fieldKey in this.rules) {
          this.$set(this.errors[i], fieldKey, "");
        }
      }
    } else {
      for (const fieldKey in this.rules) {
        this.$set(this.errors, fieldKey, "");
      }
    }
  },
};


export default formMixin;
