<template>
    <v-row class="row">
        <v-col v-for="n in 8" :key="n" cols="12" lg="4" md="4" sm="6" xl="3">
            <v-skeleton-loader elevation="4" type="card"></v-skeleton-loader>
        </v-col>
    </v-row>
</template>

<script>
export default {
  name: "ProjectListSkeleton",
};
</script>

<style scoped>

</style>
