<template>
    <v-dialog :value="isPasswordChange" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">Change Password</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <password-input v-model="model.password" :error-messages="errors.password" dense
                                            label="Password*" @input="resetField('password')"></password-input>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="isPasswordChange=false">
                    Cancel
                </v-btn>
                <v-btn color="primary" text @click="onChangePasswordSubmit">
                    Change Password
                </v-btn>
            </v-card-actions>
            <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
                <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PasswordInput from "@/components/Inputs/PasswordInput";
import { mapActions } from "vuex";

export default {
  name: "PasswordModal",
  mixins: [formMixin],
  components: {
    PasswordInput,
  },
  data: () => ({
    isBusy: false,
    model: {
      uuid: null,
      password: "",
    },
    isPasswordChange: false,
  }),
  methods: {
    onChangePassword({ uuid }) {
      this.isPasswordChange = true;
      this.model.uuid = uuid;
      this.model.password = "";
    },
    onChangePasswordSubmit() {
      this.isBusy = true;
      this.updateUser({ uuid: this.model.uuid, password: this.model.password })
          .then((user) => {
            this.$success(`User password for ${ user.email } was changed`);
            this.isPasswordChange = false;
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    ...mapActions(["updateUser"]),
  },
  created() {
    this.$eventBus.$on("user:password", this.onChangePassword);
  },
  beforeDestroy() {
    this.$eventBus.$off("user:password", this.onChangePassword);
  },
};
</script>
