<template>
    <div class="login-page">
        <img class="logo-image" src="@/assets/images/logo.svg">
        <v-row align="center" justify="center" no-gutters>
            <v-col class="d-xs-none d-sm-block" cols="12" md="6" sm="12">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10" sm="10" xl="6">
                        <v-card class="title-card" color="warning" dark elevation="12">
                            <h1>Design</h1>
                            <h2>Optics</h2>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10" sm="10" xl="6">
                        <form @submit.prevent="onFormSubmit">
                            <v-card class="form-card px-6 py-3" elevation="12">
                                <v-toolbar color="primary" dark>
                                    <v-toolbar-title>Login form</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-text-field
                                            v-model="model.email"
                                            :error-messages="errors.email"
                                            class="mt-3"
                                            label="Login"
                                            name="login"
                                            prepend-icon="mdi-account-circle"
                                            type="text"
                                            @input="resetField('email')"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="model.password"
                                            :append-icon="passwordIcon"
                                            :error-messages="errors.password"
                                            :type="passwordInputType"
                                            class="mt-3"
                                            label="Password"
                                            name="password"
                                            prepend-icon="mdi-lock"
                                            @input="resetField('password')"
                                            @click:append="showPassword=!showPassword"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :loading="isLoading" block color="primary" rounded type="submit" x-large>
                                        Login
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </form>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import { mapActions, mapMutations } from "vuex";

export default {
  mixins: [formMixin],
  data: () => ({
    model: {
      email: "",
      password: "",
    },
    rules: {
      email: { isRequired: "This field is required" },
      password: { isRequired: "This field is required" },
    },
    isLoading: false,
    showPassword: false,
  }),
  computed: {
    passwordIcon() {
      return this.showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline";
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    onFormSubmit() {
      if (!this.validate()) {
        return;
      }
      console.log("Form Submit");
      this.isLoading = true;
      const authorize = {
        strategy: "local",
        email: this.model.email,
        password: this.model.password,
      };
      this.login(authorize)
          .then(([restResponse, wsResponse]) => {
            this.isLoading = false;
            if (restResponse.user && wsResponse.user && restResponse.user.uuid == wsResponse.user.uuid) {
              this.$success("Logged In successfully");
              this.setUserAuthInfo(restResponse.user);
              this.$router.push({ path: "/" });
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    ...mapActions(["login"]),
    ...mapMutations(["setUserAuthInfo"]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.email) {
        vm.email = to.query.email;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.email) {
      this.email = to.query.email;
      next();
    }
  },
};
</script>
<style lang="scss">
.login-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: center / contain no-repeat url("~@/assets/images/bg.svg"),
  #eee 35% url("~@/assets/images/bg.svg");
  background-size: cover;

  .title-card {
    h1 {
      font-size: 4em;
      padding: 0.5em 0.0em 0.0em 0.5em;
    }

    h2 {
      font-size: 4em;
      padding: 0em 0.25em 0.25em 3.25em;
    }
  }

  .logo-image {
    position: fixed;
    left: 25px;
    top: 25px;
    width: 100px;
    height: 100px;
  }
}
</style>
