<template>
    <v-text-field :append-icon="passwordIcon"
                  :dense="dense"
                  :error-messages="errorMessages"
                  :label="label"
                  :type="passwordInputType"
                  :value="value"
                  append-outer-icon="mdi-dice-multiple-outline"
                  @input="$emit('input',$event)"
                  @click:append="showPassword=!showPassword"
                  @click:append-outer="onRandomPassword"
    >
    </v-text-field>
</template>

<script>
import { randomString } from "@/util";

export default {
  name: "PasswordInput",
  props: {
    value: {
      type: String,
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    errorMessages: {
      type: [String, Array],
      default() {
        return "";
      },
    },
    dense: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    passwordIcon() {
      return this.showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline";
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    onRandomPassword() {
      const password = randomString(20, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!()-$%&?*_+=/;,.#@^~");
      this.$emit("input", password);
    },
  },
};
</script>

<style scoped>

</style>
