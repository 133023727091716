<template>
    <div class="notify-pool">
        <notify-alert v-for="notification in pool"
                      :key="notification.key"
                      :dismissible="notification.dismissible"
                      :message="notification.message"
                      :timeout="notification.timeout"
                      :type="notification.type">
        </notify-alert>
    </div>
</template>
<script>
import NotifyAlert from "@/components/Notify/NotifyAlert";
import { mapGetters } from "vuex";

export default {
  name: "NotifyPool",
  components: {
    [NotifyAlert.name]: NotifyAlert,
  },
  computed: {
    ...mapGetters({
      pool: "getAlertPool",
    }),
  },
};
</script>
<style lang="scss">
.notify-pool {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  width: 50%;
  padding: 0.25em 0.5em;
}
</style>
