import { apiClient } from "@/api";
import Vue from "vue";
import { isPrimitive } from "@/util";

export default {
  state: {
    settings: {},
  },
  getters: {
    getSettings: ({ settings }) => settings,
  },
  mutations: {
    setSettings({ settings }, newSettings) {
      for (const setting of newSettings) {
        Vue.set(settings, setting.id, setting.isSerialized ? JSON.parse(setting.value) : setting.value);
      }
      console.log(settings);
    },
  },
  actions: {
    fetchSettings({ state, commit }) {
      return new Promise((resolve, reject) => {
        apiClient
          .service("settings")
          .find()
          .then((data) => {
            commit("setSettings", data);
            resolve(state.settings);
          })
          .catch(reject);
      });
    },
    updateSettings({ state, commit }, settingsPatch) {
      const settingsArray = [];
      for (const id in settingsPatch) {
        let isSerialized = !isPrimitive(settingsPatch[id]);
        settingsArray.push({
          id,
          value: isSerialized ? JSON.stringify(settingsPatch[id]) : settingsPatch[id],
          isSerialized,
        });
      }
      return new Promise((resolve, reject) => {
        apiClient
          .service("settings")
          .patch(null, settingsArray)
          .then((data) => {
            commit("setSettings", data);
            resolve(state.settings);
          })
          .catch(reject);
      });
    },
  },
};
