import feathers from "@feathersjs/client";
import socketio from "@feathersjs/socketio-client";
import axios from "axios";
import io from "socket.io-client";
import eventBus from "../event";

const baseURL = process.env.VUE_APP_API_BASE_URL || "https://staging-image-ai.synqrinus.net";
const restClient = feathers.rest(baseURL);
const socket = io(baseURL);

function sleep(ms = 2000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

if (process.env.NODE_ENV === "development") {
  axios.interceptors.response.use(async (response) => {
    console.log("Intercept response");
    await sleep();
    return response;
  });
}

socket.on("connect", () => {
  if (localStorage.getItem("feathers-jwt")) {
    socket.emit("create", "authentication", {
      strategy: "jwt",
      accessToken: localStorage.getItem("feathers-jwt"),
      // eslint-disable-next-line no-unused-vars
    }, function (error, newAuthResult) {
    });
  }
});


const socketClient = feathers();
socketClient.configure(socketio(socket));
socketClient.configure(feathers.authentication());

const apiClient = feathers();
apiClient.configure(restClient.axios(axios));
apiClient.configure(feathers.authentication());
apiClient.hooks({
  error({ error }) {
    // TODO - redirect to login in case of 401
    eventBus.$emit("api:error", error);
  },
});

const simpleClient = axios.create({
  baseURL,
});

export { apiClient, simpleClient, socketClient, socket };
