import Vue from "vue";

const crypto = require("crypto");

/**
 * Checks if value is scalar
 * @param value
 * @returns {boolean}
 */
export function isPrimitive(value) {
  return value !== Object(value);
}

/**
 * Check if value is Object or Array
 * @param value
 * @returns {boolean}
 */
export function isIterable(value) {
  return value === Object(value);
}

/**
 * Copies object fields from src to dst with setting up reactivity
 * @param src Source object
 * @param dst Destination object
 */
export function safeCopy(src, dst) {
  if (dst && src) {
    for (const key in src) {
      if (isPrimitive(src[key]) && src[key] !== null) {
        Vue.set(dst, key, src[key]);
      }
    }
  }
}

/**
 * Recursive deep copy object with reactivity setting
 * @param src
 * @param dst
 */
export function safeDeepCopy(src, dst) {
  if (dst && src) {
    for (const key in src) {
      if (isIterable(src[key])) {
        if (!isIterable(dst[key])) {
          Vue.set(dst, key, Array.isArray(src[key]) ? [] : {});
        }
        safeCopy(src[key], dst[key]);
      } else {
        Vue.set(dst, key, src[key]);
      }
    }
  }
}

/**
 * Prepare data structure for multipart/form-data request
 * @param data
 * @returns {FormData}
 */
export function prepareMultipartFormData(data) {
  const formData = new FormData();
  for (const field in data) {
    if (Array.isArray(data[field])) {
      for (const item of data[field]) {
        if (item instanceof File) {
          formData.append(`${ field }[]`, item, item.name);
        } else {
          formData.append(`${ field }[]`, item.url);
        }
      }
    } else {
      formData.append(field, data[field]);
    }
  }
  return formData;
}

/**
 * Format string as date
 * @param value
 * @returns {string}
 */
export function dateFormat(value) {
  return (new Date(value)).toDateString();
}

/**
 * Format string as decimal
 * @param value
 * @returns {string}
 */
export function decimalFormat(value) {
  return value.toFixed(2);
}


/**
 * Simple throttle function that executes a passed function only once in the specified timeout
 * @param handlerFunc
 * @param [timeout] the throttle interval
 */
export function throttle(handlerFunc, timeout = 66) {
  let resizeTimeout;
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      handlerFunc();
      // The actualResizeHandler will execute at a rate of 15fps
    }, timeout);
  }
}

/**
 * Generates strong random password
 * @param length - password length
 * @param chars - characters set string
 * @returns {string}
 */
export function randomString(length, chars) {
  if (!chars) {
    throw new Error("Argument 'chars' is undefined");
  }

  const charsLength = chars.length;
  if (charsLength > 256) {
    throw new Error("Argument 'chars' should not have more than 256 characters"
      + ", otherwise unpredictability will be broken");
  }

  const randomBytes = crypto.randomBytes(length);
  let result = new Array(length);

  randomBytes.reduce((previousValue, currentValue) => {
    result.push(chars[(previousValue + currentValue) % charsLength]);
    return previousValue + currentValue;
  }, 0);

  return result.join("");
}

/**
 * Filers object by removing keys provided by array
 * @param object - input object
 * @param forbidden - exlude keys array
 * @returns {{}}
 */
export function filterObjectForbidden(object, forbidden) {
  return Object.keys(object)
               .filter((key) => !forbidden.includes(key))
               .reduce((obj, key) => {
                 obj[key] = object[key];
                 return obj;
               }, {});
}

/**
 * Convert coordinates from screen to svg coordinate system
 * @param svgElement
 * @param screenX
 * @param screenY
 * @returns {DOMPoint}
 */
export function screenToSVG(svgElement, screenX, screenY) {
  const p = svgElement.createSVGPoint();
  p.x = screenX;
  p.y = screenY;
  return p.matrixTransform(svgElement.getScreenCTM().inverse());
}

/**
 * Convert coordinates from SVG to screen coordinate system
 * @param svgElement
 * @param svgX
 * @param svgY
 * @returns {DOMPoint}
 * @constructor
 */
export function SVGToScreen(svgElement, svgX, svgY) {
  const p = svgElement.createSVGPoint();
  p.x = svgX;
  p.y = svgY;
  return p.matrixTransform(svgElement.getScreenCTM());
}

/**
 * Check if rectangle contains point
 * @param rect
 * @param x
 * @param y
 * @returns {boolean}
 */
export function rectContains(rect, { x, y }) {
  return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height;
}

/**
 * Get path part from URL
 * @param url
 * @returns {string}
 */
export function getUrlPath(url) {
  const regex = /^https?:\/\/.+?(\/[^?]*).*$/;
  const matches = regex.exec(url);
  return matches ? matches[1] : "";
}
