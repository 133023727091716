<template>
    <v-row class="row">
        <v-col v-if="role === 'ADMIN' || role==='COMPANY' || userUuid===model.ownerUuid" cols="12" lg="4" md="4" sm="12"
               xl="4"
               xs="12">
            <v-row class="px-4 py-4">
                <v-col cols="12">
                    <form @input="isDirty=true">
                        <v-text-field v-model="model.name" :error-messages="errors.name"
                                      filled label="Project name" placeholder="Project name"
                                      @input="resetField('name')"></v-text-field>
                        <v-textarea v-model="model.description" class="mb-2" filled
                                    label="Description"
                                    placeholder="Description" rows="5">
                        </v-textarea>
                        <v-text-field v-model="model.keywords" filled label="Keywords"
                                      placeholder="Keywords"></v-text-field>
                    </form>
                </v-col>
                <v-col class="d-flex flex-column justify-center align-left" cols="6">
                    <div class="primary--text card-owner">BY {{ model.owner.firstName }}
                        {{ model.owner.lastName }}
                    </div>
                </v-col>
                <v-col cols="6">
                    <v-btn v-if="!samples.length && !model.isArchive" color="error" dark large @click="onProjectDelete">
                        <v-icon class="mr-2">mdi-delete-alert-outline</v-icon>
                        Delete Project
                    </v-btn>
                </v-col>
                <div v-if="isDirty" class="mt-4">
                    <v-btn class="mr-4" color="primary" text @click="onProjectUpdate">
                        <v-icon class="mr-2">mdi-check-bold</v-icon>
                        Save Changes
                    </v-btn>
                    <v-btn class="ml-4" color="default" text @click="onProjectDiscard">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Discard changes
                    </v-btn>
                </div>
            </v-row>
        </v-col>
        <v-overlay :value="isBusy" absolute color="white" opacity="0.8">
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "PropertiesTab",
  mixins: [formMixin],
  components: {
    //Badge
  },
  data: () => ({
    isBusy: false,
    isDirty: false,
  }),
  computed: {
    keywordsArray() {
      return this.project.keywords ? this.project.keywords.split(",") : [];
    },
    ...mapGetters({
      samples: "getMediaList",
      model: "getProject",
      role: "authRole",
      userUuid: "authUserId",
    }),
  },
  methods: {
    onProjectDiscard() {
      this.isBusy = true;
      this.resetForm();
      this.fetchProject(this.model.uuid)
          .then(() => {
            this.isBusy = false;
            this.isDirty = false;
          })
          .catch(() => {
            this.isBusy = false;
          });
    },
    onProjectUpdate() {
      this.resetForm();
      this.isBusy = true;
      this.updateProject({
        uuid: this.model.uuid,
        projectData: {
          name: this.model.name,
          description: this.model.description,
          keywords: this.model.keywords,
        },
      })
          .then(() => {
            this.$success(`Project "${ this.model.name }" updated successfully`);
            this.isDirty = false;
          })
          .catch(this.handleServerErrors)
          .finally(() => {
            this.isBusy = false;
          });
    },
    onProjectDelete() {
      const name = this.model.name;
      this.$confirm("Delete project", `Are you sure you want to delete project "${ name }"`)
          .yes(() => {
            this.isBusy = true;
            this.deleteProject(this.model.uuid)
                .then(() => {
                  this.$success(`Project ${ name } has been deleted`);
                  this.$router.push({ name: "ListProjects" });
                })
                .finally(() => {
                  this.isBusy = false;
                });
          });
    },
    ...mapActions(["updateProject", "fetchProject", "deleteProject"]),
  },
};
</script>

<style scoped>
</style>
