<template>
    <v-container fill-height fluid style="background: #f4f3ef; align-items: start">
        <v-navigation-drawer
                v-model="sidebarVisibility"
                :mini-variant="drawerMinify"
                :permanent="!isMobileMode"
                :style="{zIndex:isMobileMode?3000:6}"
                :temporary="isMobileMode"
                app
                class="app-sidebar"
                dark
                mobile-breakpoint="sm">
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-icon>
                        <v-img class="rounded-br-0" src="@/assets/images/logo.svg">
                            <a class="d-block" href="https://www.synqrinus.com" style="width: 100%;height: 100%"
                               target="_blank"></a>
                        </v-img>
                    </v-list-item-icon>
                    <v-list-item-title class="text-h6">
                        Design Optics
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav>
                <v-list-item
                        v-for="({path,icon,name},index) in navLinks[authRole]"
                        :key="index"
                        :to="path" class="pl-3" color="primary" link
                >
                    <v-list-item-icon>
                        <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app light style="z-index: 100">
            <v-app-bar-nav-icon v-if="!isMobileMode" @click="drawerMinify = !drawerMinify"></v-app-bar-nav-icon>
            <v-app-bar-nav-icon v-else @click="mobileMenu=!mobileMenu"></v-app-bar-nav-icon>
            <v-breadcrumbs :items="$route.meta.breadcrumbs" large></v-breadcrumbs>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="{name: 'CreateProject'}" color="primary" depressed large>
                <v-icon>mdi-plus</v-icon>
                Create Project
            </v-btn>

            <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'ProfilePage'}" link>
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>


        </v-app-bar>
        <v-main>
            <v-container fluid>
                <v-slide-x-transition mode="out-in">
                    <router-view/>
                </v-slide-x-transition>
            </v-container>
        </v-main>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import navLinks from "./sidebarLinks";

export default {
  name: "InternalLayout",
  data: () => ({
    navLinks,
    drawerMinify: false,
    mobileMenu: false,
  }),
  computed: {
    sidebarVisibility: {
      get: function () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? this.mobileMenu : true;
      },
      set: function (newValue) {
        if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
          this.mobileMenu = newValue;
        }
      },
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    ...mapGetters(["authRole"]),
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style lang="scss">
.app-sidebar {
  background: url("~@/assets/images/sidebar-bg-new.png") no-repeat;
  background-position: bottom center;
  background-size: contain;
}
</style>
